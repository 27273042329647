const translationsEn = {
    "status": "success",
    "data": {
        "lang": "ru_RU",
        "app": "hotel",
        "translation": {
            "crud": {
                "validationFailed": "Проверка не прошла",
                "operationError": "Произошла ошибка",
                "emptyList": "Данные не найдены",
                "failToLoad": "Ошибка при загрузке",
                "modelNotFound": "Не найдено"
            },
            "upgradePlan": {
                "general": {
                    "upgrade_plan": "План обновления",
                    "upgrade_plan_to_regular": "Обновить до обычного плана",
                    "upgrade_plan_to_pro": "Обновить до плана PRO",
                  },
                "regular": {
                    "title": "Обновите сейчас и получите доступ к продуктам Agent 360!",
                    "benefits_title_text": "В настоящее время вы подключены к пакету Agent 360 Light. Если вы обновитесь, вы сможете получить",
                    "text_1": "Оптовые продукты Sky 360",
                    "text_2": "Отели для всех направлений по всему миру",
                    "text_3": "Регулярные и бюджетные рейсы",
                    "text_4": "Достопримечательности по всему миру",
                    "button_text": "Обновите сейчас до Agent 360!"
                },
                "pro": {
                    "title": "Обновите сейчас и получите веб-сайт для вашего бизнеса с полным брендингом!",
                    "benefits_title_text": "В пакете SKY 360 Pro вы можете наслаждаться",
                    "text_1": "Веб-сайт с вашим уникальным доменом",
                    "text_2": "Брендинг бизнеса - логотип и цвета сайта",
                    "text_3": "Продвижение пакетов и рейсов на сайте по направлениям",
                    "text_4": "Все бронирования будут принадлежать вашему агентству!",
                    "button_text": "Обновите сейчас до Pro!"
                }
            },
            "attractionQuestions": {
                "customerInfo": "Customer Info",
                "participantsInfo": "Participants Info",

                "first_name": "First Name",
                "last_name": "Last Name",
                "email_address": "Email Address",
                "phone_number": "Phone Number",
                "pickup_location": "Pickup Location",
                "height_in_centimeters": "Height in Centimeters",
                "weight_in_kilograms": "Weight in Kilograms",
            },
            "contactUs": {
                "requestForQuotation": "Запрос на предложение",
                "problemWithExistingOrder": "Проблема с существующим заказом",
                "customerService": "Обслуживание клиентов",
                "other": "Другое",
                "messageReceivedSuccessfully": "Сообщение успешно получено",
                "email": "Электронная почта",
                "phone": "Телефон",
                "fullName": "Полное имя",
                "writeUsMessage": "Напишите нам сообщение",
                "send": "Отправить",
                "phoneRequired": "Пожалуйста, введите номер телефона",
                "emailRequired": "Пожалуйста, введите адрес электронной почты",
                "fullNameRequired": "Пожалуйста, введите полное имя",
                "messageRequired": "Пожалуйста, введите сообщение",
                "aboutUs": "О нас"
            },
            "cart": {
                "addToCart": "Добавить в корзину"
            },
            "customerSupport": {
                "customerService": "Обслуживание клиентов",
                "sendMessageAndGetNotified": "Отправьте нам сообщение, и аукционист свяжется с вами как можно скорее",
                "callUs": "Позвоните нам: "
            },
            "roomInfo": {
                "info": "Описание номера",
                "viewAllThePhotos": "Просмотреть все фотографии",
                "roomSize": "Размер номера (кв. м)",
                "peopleInTheRoom": "Люди в номере",
                "doubleBed": "Двуспальная кровать"
            },
            "conceptsList": {
                "conceptsListTitle": "Заголовок списка концепций",
                "includesActivities": "Подарочные аттракционы",
                "includesActivity": "Включает подарочный аттракцион",
                "includeInPackage": "Включено в пакет",
                "transfer": "Трансфер",
                "includesTransfer": "Включает трансфер",
                "transferContent": "Трансферы HOS по цене пакета",
                "baseBoard": "Основной план",
                "autoApprove": "Автоматическое одобрение",
                "airways": "Авиалинии",
                "flightFrom": "Рейс из",
                "flightBack": "Обратный рейс",
                "nights": "Ночей",
                "toOrder": "Заказать",
                "noPackagesFound": "Пакет не найден",
                "moreDeals": "Больше предложений",
                "pricePerPerson": "Цена за человека"
            },
            "viewFlight": {
                "show_tickets_with_different_fare_combinations": "Показать билеты с разными тарифными комбинациями",
                "ticketFare": "Тариф",
                "ticketInfo": "Информация о билете",
                "termsAndConditions": "Условия и положения",
                "ifTicketIncludeSeat": "Если билет включает место - клиенту нужно выбрать его на сайте авиакомпании",
                "inCaseOfChange": "В случае изменения - клиенту нужно будет доплатить, если новый билет дороже, а также есть дополнительная плата за обслуживание согласно условиям сайта",
                "inCaseOfCancellation": "В случае отмены - будет взиматься дополнительная плата за обслуживание согласно условиям сайта",
                "error4305Title": "Упс, рейс полностью забронирован",
                "error4305Content": "Извините, но похоже, что места на это предложение распроданы, и мы не можем продолжить покупку. Вы будете перенаправлены на главную страницу.",
                "TC": "Условия",
                "flights": "Рейсы",
                "title": "Выбранный рейс, проверьте детали и перейдите к оплате",
                "addBaggage": "Добавить багаж",
                "closeAddBaggage": "Скрыть опции",
                "addTrolly": "Добавить тележку",
                "somethingWetWrong": "Что-то пошло не так",
                "suitcasePrice": "Цена чемодана",
                "trollyPrice": "Цена тележки",
                "totalPerPassenger": "Итого за пассажира",
                "updatePassengers": "Обновить количество пассажиров",
                "search": "Поиск",
                "seating": "Места",
                "cancelation": "Отмена билета",
                "meal": "Питание",
                "trolly": "Тележка",
                "suitcase": "Чемодан",
                "changeFlight": "Изменить билет",
                "extension": "Продление",
                "totalToBePaid": "К оплате",
                "choose": "Выбрать",
                "checkAmenities": "Проверить удобства",
                "description": "Описание",
                "amenityType": "Тип удобства",
                "flightFromAmenities": "Удобства на вылете",
                "flightBackAmenities": "Удобства на обратном рейсе",
                "BAGGAGE": "Багаж",
                "2 CHECKED BAGS UP TO 32KG EACH": "2 зарегистрированных багажа до 32 кг каждый",
                "2 CABIN BAGS UP TO 8KG": "2 ручные клади до 8 кг",
                "BRANDED_FARES": "Фирменные тарифы",
                "STANDARD SEAT RESERVATION": "Стандартное бронирование места",
                "MILEAGE_ACCRUAL": "Начисление миль",
                "MEAL": "Питание",
                "SNACK": "Перекус",
                "COMPLIMENTARY FOOD AND BEV": "Бесплатное питание и напитки",
                "ENTERTAINMENT": "Развлечения",
                "MAGAZINES / NEWSPAPER": "Журналы / Газеты",
                "LOUNGE": "Зал ожидания",
                "LOUNGE ACCESS": "Доступ в зал ожидания",
                "TRAVEL_SERVICES": "Туристические услуги",
                "PRIORITY BAGGAGE": "Приоритетный багаж",
                "PRIORITY CHECK IN": "Приоритетная регистрация",
                "PRIORITY SECURITY": "Приоритетная безопасность",
                "PRIORITY BOARDING": "Приоритетная посадка",
                "IN FLIGHT ENTERTAINMENT": "Развлечения в полете",
                "PRE_RESERVED_SEAT": "Предварительно забронированное место",
                "PRIVACY SEAT BUSINESS CLASS": "Приватное место бизнес-класса",
                "CHANGE BEFORE DEPARTURE": "Изменение до вылета",
                "CHANGE AFTER DEPARTURE": "Изменение после вылета",
                "REFUND BEFORE DEPARTURE": "Возврат до вылета",
                "REFUND AFTER DEPARTURE": "Возврат после вылета",
                "allFares": "Все тарифы",
                "fareChangedSuccessfully": "Тариф успешно изменен",
                "openFares": "Открытые тарифы",
                "selectedFare": "Выбранный тариф",
                "fullInfo": "Полная информация",
                "MILEAGE ACCRUAL": "Начисление миль"
            },
            "room": {
                "roomSize": "Размер номера",
                "bedType": "Тип кровати",
                "breakfastIncluded": "Завтрак включен",
                "noAvailabilityOnSelectedDates": "Нет доступности для этой комнаты на выбранные даты"
            },
            "promotion": {
                "modelNotFound": "Страница не найдена",
                "failToLoad": "Не удалось загрузить страницу!"
            },
            "searchDatePicker": {
                "freePlanFlightSearchFooterText": "Бесплатный план позволяет искать только чартерные рейсы! Если вы хотите GDS рейсы",
                "clickHere": "Нажмите здесь",
                "approveSelection": "Подтвердить выбор",
                "room": "Комната",
                "addRoom": "Добавить комнату",
                "removeRoom": "Удалить комнату",
                "indirectFlight": "Рейс с пересадками",
                "directFlightLabel": "Прямой рейс",
                "city": "Город",
                "hotel": "Отель",
                "searchForDestination": "Поиск по направлениям",
                "searchByDestinationAndHotel": "Поиск по направлениям и отелям",
                "date": "Дата",
                "noResultsForThisDate": "Пакеты не найдены на эту дату",
                "searchDestination": "Поиск направления",
                "pleaseSelectDestination": "Пожалуйста, выберите направление",
                "airlineInformation": "Информация об авиакомпании",
                "airlineCode": "Код",
                "title": "Забронируйте свой идеальный отпуск",
                "hotelOnly": "Поиск отпуска по датам",
                "hotelWithFlight": "Поиск отпуска с рейсом",
                "oneWay": "В одну сторону",
                "roundTrip": "Туда и обратно",
                "selectDate": "Выбор дат",
                "startDate": "Дата начала",
                "endDate": "Дата окончания",
                "flights": "Поиск рейсов",
                "packages": "Пакеты отдыха",
                "hotels": "Отели за границей",
                "israelPackage": "Отдых в Израиле",
                "specialPackage": "Специальные пакеты",
                "passengers": "Пассажиры",
                "adults": "Взрослые",
                "adult": "Взрослый",
                "child": "Ребенок",
                "children": "Дети",
                "infant": "Младенец",
                "infants": "Младенцы",
                "infantsWarningMessage": "Младенец должен быть младше двух лет на момент вылета и возвращения",
                "select": "Выбрать",
                "noResults": "Нет результатов",
                "checkout": "Забронировать сейчас",
                "regularPrice": "Обычная цена",
                "from": "От",
                "purchase": "Купить",
                "selectNewFlight": "Выбрать новый рейс",
                "search": "Поиск",
                "rooms": "Номера",
                "composition": "Состав",
                "chooseComposition": "Выбрать состав",
                "numberOfGuests": "Количество гостей",
                "checkOut": "Выезд",
                "checkIn": "Заезд",
                "whatVacation": "Какой вид отдыха",
                "youWant": "вы хотите",
                "vacationFriendsWith": "Отдых с друзьями",
                "familyVacation": "Семейный отдых",
                "romanticVacation": "Романтический отдых",
                "notAvailable": "Недоступно",
                "roomAvailableDuringTheWeek": "Доступность номера в течение недели",
                "followingDates": "Следующие даты",
                "vacation": "Отдых",
                "abroad": "За границей",
                "inIsrael": "В стране",
                "destination": "Все направления",
                "chooseDestination": "Выберите направления",
                "findDreamVacation": "Найдите свой идеальный отпуск одним кликом.",
                "searchResTitle": "Выберите подходящий пакет для вас",
                "directFlight": "Прямой рейс",
                "stops": "остановки",
                "checkHotel": "Проверить отель",
                "noRating": "Нет рейтинга",
                "toOrder": "Заказать",
                "selectAgain": "Выбрать снова",
                "selectFlight": "Выбрать рейс",
                "reselectFlight": "Выбрать рейсы снова",
                "flightSelectedSuccessfully": "Рейс успешно выбран",
                "dynamicFlight": "Динамический рейс",
                "bundle": "Скомбинированный рейс",
                "freeCancelation": "Бесплатная отмена",
                "partially_operated_by_airlines": "Частично обслуживается авиакомпаниями",
                "connect_in_airport": "Пересадка в аэропорту",
                "arrives": "Прибытие",
                "journey_duration": "Продолжительность поездки",
                "short_wait": "Короткое ожидание",
                "long_wait": "Долгое ожидание",
                "flight_number": "Номер рейса",
                "stop_1": "Одна остановка",
                "activeFlight": "Активный рейс",
                "flightSearch": "Поиск рейса",
                "nights": "Ночей",
                "until": "До даты",
                "untilYouSearchForFlightDate": "До даты поиска рейса",
                "destinationFrom": "Отправление",
                "packageSearch": "Поиск пакета",
                "destinationTo": "Прибытие",
                "selectDestination": "Направление",
                "trollyIsIncluded": "Тележка включена",
                "trollyIsNotIncluded": "Тележка не включена",
                "suitcaseIsIncluded": "Чемодан включен",
                "suitcaseIsNotIncluded": "Чемодан не включен",
                "operated_by_airlines": "Обслуживается авиакомпанией"
            },
            "specialDeal": {
                "closeTheDeal": "Заключить сделку ›",
                "endsIn": "Заканчивается через",
                "specialDealForTheWeekend": "Специальное предложение на выходные",
                "from": "С даты",
                "until": "До даты"
            },
            "footer": {
                "backToTop": "Вернуться наверх",
                "allRightsReserved": "© Все права защищены. Запрещено копирование контента с сайта без разрешения.",
                "developBy": "Разработано SKY 360 Tech.",
                "thePurchaseOnTheWebsiteIsSecure": "Покупка на сайте безопасна.",
                "talkToUs": "Как с нами связаться?",
                "address": "Адрес",
                "phoneAndWhatsapp": "Телефон или WhatsApp",
                "phone": "Телефон",
                "whatsapp": "WhatsApp"
            },
            "packages": {
                "availableSeats": "Доступные места",
                "from": "С даты",
                "until": "До даты",
                "breakfastIncluded": "Завтрак включен",
                "includesMassageAndSpa": "Включает массаж и спа!",
                "lastRoom": "Последний номер!",
                "includesTransfers": "Включает трансферы",
                "includesActivities": "Включает аттракционы",
                "nights": "Ночей",
                "withoutBaggage": "Без багажа",
                "directFlight": "Прямой рейс",
                "oneStop": "Одна остановка",
                "numOfStops": "Остановок",
                "flightDetails": "Детали рейса",
                "flightFrom": " из -> в",
                "flightBack": " из -> в",
                "hours": "Часы",
                "flightNumber": "Номер рейса",
                "airLine": "Авиалиния",
                "suitcaseWeight": "вескг Включенный багаж",
                "trollyWeight": "вескг Включена тележка",
                "includedActivitiesTitle": "Аттракционы и туры в пакете",
                "checkAllPictures": "Просмотреть все фотографии",
                "affiliateWith": "В сотрудничестве с",
                "addButtonDisabledTooltip": "Заказ ограничен количеством номеров - нельзя добавить еще один номер. Чтобы изменить номер, удалите номер из корзины",
                "holidaySchedule": "График отпуска",
                "passengers": "Пассажиры",
                "isRoundTrip": "Цена багажа для кругового путешествия *",
                "includesFlights": "Включает рейсы",
                "returnFlight": "Обратный рейс",
                "outboundFlight": "Исходящий рейс",
                "trollyWeight2": "вес кг тележка / сумка",
                "suitcaseWeight2": "вес кг чемодан",
                "included": "Включено",
                "notIncluded": "Не включено",
                "perPax": "За пассажира",
                "addSuitcaseLater": "Можно добавить позже",
                "clickForDetails": "Нажмите здесь для деталей аттракциона",
                "includedInPackage": "Включено в пакет!"
            },
            "roomPricing": {
                "for": "для",
                "addPassenger": "Добавить пассажира",
                "pricePerPerson": "Цена за человека",
                "pricePerRoom": "Цена за номер",
                "priceForAllRooms": "Цена за все номера",
                "payOnSpot": "Оплата на месте",
                "resort_fee": "Курортный сбор",
                "occupancy_tax": "Налог на проживание",
                "totalPrice": "Общая цена",
                "pricePerInfant": "Цена за младенца",
                "composition": "Состав",
                "adultsInTheRoom": "Взрослые",
                "children": "Дети",
                "adults": "Взрослые",
                "single": "Один взрослый",
                "child": "Ребенок",
                "selectInfantCount": "Выберите из списка",
                "infant": "Младенец",
                "1infant": "Один младенец",
                "2infants": "2 младенца",
                "infants": "Младенцы",
                "noInfants": "Без младенцев",
                "all_include": "Все включено",
                "breakfast": "Завтрак",
                "activities": "Выберите тур",
                "half_board": "Полупансион",
                "half_board_kosher": "Кошерный полупансион",
                "full_board": "Полный пансион",
                "sleep_only": "Только номер",
                "breakfast_kidush": "Утренняя и вечерняя трапеза в пятницу",
                "addToCard": "Добавить в заказ",
                "inPackageDates": "В пакетные даты",
                "chooseRoom": "Выберите состав номера",
                "nights": "Ночей",
                "boardType": "Тип питания",
                "filters": "Фильтры",
                "noAvailableRooms": "Нет доступных номеров на выбранные даты",
                "noAvailableRoomsForDates": "Нет доступных номеров на выбранные даты",
                "addInfant": "Добавить младенца",
                "roomFacilities": "Удобства в номере",
                "show_images": "Показать изображения",
                "autoApprove": "Автоматическое подтверждение",
                "includedInPrice": "Включено в стоимость",
                "until": "До",
                "no": "Нет",
                "vat": "НДС",
                "is": "Есть",
                "not": "Нет",
                "city_tax": "Городской налог",
                "cancellation_policy": "Политика отмены",
                "your_local_time": "*Ваше местное время",
                "additional_price_info": "Дополнительная информация о цене",
                "policy": {
                    "freeCancallationUntil": "Бесплатная отмена по этой ставке до",
                    "freeCancallationUntil2": "Бесплатная отмена до",
                    "cancallationBeCharged": "С вас будет взиматься",
                    "ifYouCancelBetween": "Если вы отмените с",
                    "ifYouCancelModifiedBetween": "Если вы отменили или изменили после",
                    "cancelFullPrice": "будет взиматься полная стоимость бронирования",
                    "till": "до",
                    "rateNonRefundable": "Ставка не подлежит возврату при отмене"
                }
            },
            "boards": {
                "RO": "Только номер",
                "BB": "Завтрак",
                "HB": "Полупансион",
                "FB": "Полный пансион",
                "AI": "Все включено",
                "UI": "Ультра делюкс все включено",
                "BF": "Буфетный завтрак",
                "KB": "Холодный буфетный завтрак",
                "CB": "Континентальный завтрак",
                "AB": "Американский завтрак",
                "DR": "Ужин и номер",
                "EB": "Английский завтрак",
                "FT": "Полный пансион + процедуры",
                "F+": "Полный пансион плюс",
                "GA": "Праздничный ужин",
                "H6": "Полупансион + только 6 процедур",
                "HT": "Полупансион + процедуры",
                "IB": "Израильский завтрак",
                "KO": "Кошер",
                "KS": "Кошерный завтрак",
                "MI": "Максимум все включено",
                "RB": "Номер и завтрак",
                "NN": "См. маршрут",
                "SI": "Мягкое все включено",
                "UL": "Ультра все включено",
                "half_board": "Полупансион",
                "half_board_kosher": "Кошерный полупансион",
                "full_board": "Полный пансион",
                "sleep_only": "Только номер",
                "breakfast_kidush": "Утренняя и вечерняя трапеза в пятницу",
                "breakfast": "Завтрак",
                "breakfast-for-2": "Завтрак для двоих",
                "nomeal": "Только номер"
            },
            "activitiesTranslations": {
                "addToOrder": "Добавить в заказ",
                "passenger": "Пассажиру",
                "showMore": "Показать больше",
                "addAttractionsAndTrips": "Добавить аттракционы и поездки",
                "doYouWantToAddAttractions": "Хотите добавить аттракционы в ваш отпуск?",
                "itsTime": "Пришло время!",
                "pricePerPerson": "Цена за человека"
            },
            "modal": {
                "viewAllImages": "Посмотреть все изображения",
                "galleryTitle": "Фотогалерея",
                "cancelationTerms": "Условия отмены - ",
                "nameOfTheHotel": "Информация об отеле",
                "hotelInfo": "Об отеле",
                "hotelWebsite": "Ссылка на сайт отеля",
                "showMore": "Показать больше",
                "showLess": "Показать меньше"
            },
            "manageBaggage": {
                "manageBaggage": "Управление багажом",
                "and": "И",
                "save": "Сохранить",
                "purchaseIsNotSupported": "покупка не поддерживается",
                "baggageIsForBothFlights": "Вы можете добавить багаж для рейса туда и обратно",
                "suitcase": "Чемодан",
                "trolly": "Тележка",
                "guest": "Гость"
            },
            "shoppingCartTranslations": {
                "attractionPriceDescription": "Цена на аттракцион зависит от даты, выбора опции и количества билетов",
                "selectedDate": "Выбранная дата",
                "selectADate": "Выберите дату",
                "availableDatesBetween": "Доступные даты между",
                "Options": "Опции",
                "Text": "Текст",
                "Ticket type": "Тип билета",
                "Start Time": "Время начала",
                "Pick Up Location": "Место отправления",
                "Custom Pick Up Location": "Пользовательское место отправления",
                "Drop Off Location": "Место прибытия",
                "Custom Drop Off Location": "Пользовательское место прибытия",

                "manageBaggage": "Управление багажом",
                "youCanAddGuestsOnlyUpToTheAvailableSeats": "Вы можете добавить гостей только до доступного количества мест",
                "shoppingCartButtonTooltip": "Выберите состав и добавьте в заказ",
                "totalPrice": "Общая сумма",
                "proceedToPayment": "Перейти к оплате",
                "placeOrder": "Перейти к регистрации",
                "NoHotelWasChosenForTheVacation": "Отель для отпуска не был выбран",
                "betweenDates": "Между датами",
                "packageInformation": "Детали пакета",
                "rooms": "Детали номера",
                "transfers": "Трансферы",
                "reg_transfers": "Трансферы/шаттлы в отель и обратно",
                "shuttle_transfers": "Шаттлы от границы до отеля и обратно",
                "flights": "Рейсы",
                "activities": "Аттракционы",
                "shoppingCart": "Корзина",
                "package": "Ночи в отеле",
                "nights": "Ночей",
                "inHotel": "В отеле",
                "apply": "Подтвердить",
                "promoCode": "Промокод",
                "yourShoppingCart": "Ваша корзина",
                "isEmpty": "Еще пусто...",
                "myVacation": "Мой отпуск",
                "showAll": "Показать все »",
                "beforeDiscount": "Общая сумма до скидки -",
                "agentCommission": "Комиссия агента -",
                "agentCommissionNet": "Комиссия агента (чистая сумма) -",
                "discountFromCode": "Скидка по коду -",
                "affiliateWith": "В сотрудничестве с -",
                "tpId": "ID заказа -",
                "chargeTypeFullCC": "Полная оплата - включая комиссию",
                "chargeTypeNetCC": "Оплата подписки по чистой цене",
                "chargeTypeCash": "Наличная транзакция",
                "agentCommissionPrec": "Процент комиссии агента",
                "priceOff": "Скидка",
                "percentageOff": "Процент скидки",
                "selectedItems": "Выбранные позиции",
                "viewProduct": "Посмотреть продукт",
                "infantAdditionalPrice": "Дополнительная цена за младенца",
                "netPriceWarning": "Чистая цена, добавьте прибыль!",
                "copyOrderLink": "Скопировать ссылку",
                "createOrder": "Сохранить предложение",
                "updateCommission": "Сохранить",
                "commissionNotSaved": "Пожалуйста, нажмите, чтобы сохранить!",
                "clearCart": "Нажмите, чтобы очистить корзину",
                "assignCustomer": "Назначить клиента",
                "firstName": "Имя",
                "lastName": "Фамилия",
                "phone": "Телефон",
                "comment": "Комментарий",
                "email": "Электронная почта",
                "wrongEmailFormat": "Неверный формат электронной почты",
                "saveCustomer": "Сохранить клиента",
                "updateCustomerTitle": "Обновить клиента",
                "createCustomerTitle": "Создать нового клиента",
                "sendByWhatsApp": "Отправить по WhatsApp",
                "copyText": "Копировать текст",
                "sendBySMS": "Отправить по SMS",
                "findQuoteFor": "Отправить предложение через",
                "linkIsGive": "Предложение в режиме онлайн",
                "dates": "Даты",
                "hotelName": "Название отеля",
                "flightPrice": "Цена рейса",
                "suitcase": "Чемодан",
                "trolly": "Тележка",
                "passengers": "Пассажиры",
                "totalAgentComm": "Общая комиссия агента",
                "agentOptions": "Опции агента",
                "compareDisableForAgent": "Сравнение цен доступно, если в корзине есть 2 предложения",
                "createNewUser": "Создать новое предложение",
                "comparePrice": "Сравнить цены",
                "send": "Отправить предложение",
                "profitPercentageChange": "Изменить прибыль",
                "or": "Или",
                "approval": "Одобрение",
                "profitPerPerson": "Прибыль на человека",
                "grossProfitPercentage": "Процент прибыли",
                "actionNotAllowed": "Действие не разрешено",
                "orderIsAlreadySaved": "Заказ уже сохранен, и дополнительные товары нельзя добавить",
                "clickToClear": "Нажмите, чтобы очистить корзину",
                "roomsByRequestSubjectOfHotelAvailability": "Невозможно добавить тот же тип номера, выберите другой тип номера",
                "roomsByRequestSubjectOfHotelAvailabilityTitle": "Не удалось добавить номер",

                "attractionAlreadyAdded": "Невозможно добавить один и тот же тип аттракциона, пожалуйста, выберите другой тип",
                "failedToAddAttraction": "Не удалось добавить аттракцион",
                "noAvailabilityOnThisDates": "Нет доступности на выбранные даты",
                "attractionSearchFailed": "Не удалось найти аттракцион",
                "cannotSearchForThePastDates": "Невозможно выполнить поиск по прошедшим датам",
                "searchOtherForDates": "Нет доступности, пожалуйста, выберите другие доступные даты",
                "adult": "Взрослый",
                "child": "Ребенок",
                "infant": "Младенец",
                "perPerson": "За человека",
                "perVehicle": "За транспортное средство",
                "group": "Группа",
                "family": "Семья",
                "perEquipment": "За оборудование",
                "perBoat": "За лодку",
                "none": "Нет",
            },
            "hotelTypes": {
                "Hotel": "Отель"
            },
            "hotelDetails": {
                "updateDates": "Обновить даты",
                "roomTypesToChooseFrom": "Типы номеров на выбор",
                "metapolicy": "Правила отеля",
                "metaExtraInfo": "Дополнительная информация",
                "not_included": "Не включено",
                "included": "Включено",
                "price": "По цене",
                "address": "Адрес",
                "other": "Адрес",
                "Location": "Местоположение отеля",
                "At the boutique hotel": "Об отеле",
                "Room amenities": "Удобства в номере",
                "parking": "Парковка",
                "pets": "Животные",
                "shuttle": "Трансфер в аэропорт (в одну сторону)",
                "internet": "Интернет-услуги",
                "extra_bed": "Дополнительная кровать",
                "add_fee": "Дополнительная плата",
                "children_meal": "Детское питание",
                "check_in_check_out": "Регистрация заезда и выезда"
            },
            "sideFilters": {
                "sortBy": "Сортировать по",
                "recommended": "Рекомендуемые",
                "price": "Цена",
                "clearSideFilters": "Очистить фильтры",
                "ts_type": "Типы рейсов",
                "auto_approve": "Мгновенное подтверждение",
                "auto_approve_checked": "Показаны результаты с мгновенным подтверждением",
                "from_date_hours": "Время вылета",
                "return_date_hours": "Время обратного рейса",
                "airline": "Авиалиния",
                "2": "Чартерные рейсы",
                "3": "Регулярные рейсы",
                "nonStop": "Прямой рейс",
                "stop": "Одна остановка",
                "stops_plural": "Остановки",
                "hotel_type": "Типы размещения",
                "stops": "Количество остановок",
                "freeCancellation": "Бесплатная отмена?",
                "freeCancellationLabels": {
                    "1": "Только с бесплатной отменой",
                    "0": "Без бесплатной отмены"
                }
            },
            "hotelAmenities": {},
            "general": {
                "usd": "USD",
                "euro": "ЕВРО",
                "address": "Адрес",
                "type": "Тип",
                "noResults": "Нет результатов",
                "boardBasis": "Тип питания",
                "nights": "Ночей",
                "supplier": "Поставщик",
                "airLine": "Авиалиния",
                "price": "Цена",
                "totalRoomPrice": "Общая стоимость",
                "priceForXRooms": "Цена за X номеров",
                "hotel": "Отель",
                "hotel_stars": "Звезды отеля",
                "minPrice": "Минимальная цена",
                "BB": "Завтрак",
                "HB": "Полупансион",
                "cancel": "Отмена",
                "replaceShoppingCart": "Заменить корзину",
                "replace": "Заменить",
                "searchForUser": "Поиск пользователя",
                "acceptCookiesTitle": "Этот веб-сайт использует файлы cookie, чтобы вы получили лучший опыт на нашем сайте.",
                "acceptCookiesBtn": "Принять",
                "includesActivities": "{NUMBER} подарочных аттракционов!",
                "includesActivity": "Включает подарочный аттракцион!",
                "adults": "Взрослые",
                "all_include": "Все включено",
                "breakfast": "Завтрак",
                "half_board": "Полупансион",
                "half_board_kosher": "Кошерный полупансион",
                "full_board": "Полный пансион",
                "sleep_only": "Только размещение",
                "breakfast_kidush": "Завтрак и ужин в пятницу",
                "addToCard": "Добавить в заказ",
                "chooseRoom": "Выберите состав и номер",
                "boardType": "Тип размещения",
                "filters": "Фильтры",
                "rating": "Рейтинг отеля",
                "children": "Дети",
                "toDate": "До",
                "transfers": "Трансферы",
                "from": "Начиная с",
                "perPerson": "За человека",
                "include": "Включает",
                "noIncludeText": "Пакеты отдыха",
                "flights": "Рейсы",
                "vacationAt": "Отдых в {DEST}",
                "fieldRequired": "Обязательное поле",
                "showAll": "Показать все",
                "showLess": "Показать меньше",
                "flight": "рейс",
                "h": "ч",
                "min": "мин",
                "availableSeats": "Доступные места",
                "showGrossPrice": "Показать валовую цену",
                "showNetPrice": "Показать чистую цену",
                "noRating": "Без рейтинга",
                "bundleFlight": "Круговой рейс",
                "trollyIsIncluded": "Тележка включена",
                "trollyIsNotIncluded": "Тележка не включена",
                "suitcaseIsIncluded": "Чемодан включен",
                "suitcaseIsNotIncluded": "Чемодан не включен",
                "additionalSuitcases": "Включает X чемоданов",
                "additionalTrolly": "Включает X тележек",
                "returnFlight": "Обратный рейс",
                "outboundFlight": "Исходящий рейс",
                "agentMode": "Режим агента",
                "userMode": "Режим клиента",
                "suitcase": "Чемодан",
                "trolly": "Тележка",
                "packageSearchResult": "Найденные для вас пакеты",
                "day": "День",
                "selectDate": "Выберите даты",
                "search": "Поиск",
                "adult": "Взрослый",
                "child": "Ребенок",
                "infant": "Младенец",
                "infants": "Младенцы"
            },
            "subsidizedForm": {
                "title": "Добавить субсидию",
                "employee_id": "Номер сотрудника",
                "person_id": "Идентификационный номер (9 цифр)",
                "validate": "Проверить код сотрудника",
                "alreadyApplied": "Субсидия уже применена",
                "addOneMore": "Добавить еще одну субсидию",
                "addOneMoreId": "Добавить еще одного сотрудника",
                "fieldRequired": "Обязательное поле",
                "priceReducedBy": "Цена снижена на",
                "forEmployee": "Для сотрудника",
                "employeeIs": "Успешно идентифицирован",
                "error4401": "Не удалось идентифицировать, попробуйте еще раз",
                "error4402": "Код идентифицирован, но уже используется",
                "guestIdentifiedId": "Идентифицирован по ID",
                "guestNotIdentified": "Вы должны идентифицироваться перед регистрацией",
                "clickToIdentify": "Нажмите для идентификации"
            },
            "uploadImage": {
                "uploadPassportImage": "Загрузить фото паспорта",
                "somethingWentWrong": "Что-то пошло не так"
            },
            "flightButton": {
                "cardConditions": "Условия карты",
                "flightTotalPrice": "Цена на рейс в одну сторону",
                "total": "Итого",
                "continueToOrder": "Перейти к заказу",
                "addToCart": "Добавить в корзину",
                "lastPlace": "Последние доступные места",
                "pricePerPassenger": "Цена за пассажира"
            },
            "agentCommission": {
                "supplier": "Поставщик",
                "commission": "Комиссия",
                "recommendedFee": "Рекомендуемая плата",
                "transactionDetailsForAgent": "Детали для агента",
                "supplierNotes": "Примечания поставщика",
                "cancellationConditions": "Условия отмены",
                "addToCartToChangeFree": "Добавьте в корзину для изменения комиссии",
                "pricesShownAreNetPrices": "Показаны чистые цены",
                "pricesShownAreGrossPrices": "Показаны валовые цены",
                "packageRemarks": "Замечания по пакету"
            }
        }
    }
};

export default translationsEn;