import React from 'react';
import { Spin } from 'antd';

import './spin-loader.less';

interface Props {
  children?: any;
  isLoading: boolean;
  onTop?: boolean;
  size?: "large" | "default" | "small" | undefined;
}

const SpinLoader = ({ children, isLoading, onTop = false, size = 'large'}: Props) => {
  if (onTop) {
    return (
        isLoading ?
            <div className="spin-loader-on-top">
                <Spin className="spin-container" size={size} />
                {children}
            </div> :
            children
    );
  } else {
    return (
       isLoading ?
      <div className="spin-loader">
        <Spin size={size} />
      </div> :
       children
    );
  }

};

export default SpinLoader;
