import React, { useState } from 'react';
import { PlanIdType } from '../../constants/agency';
import SkyPlanBasicSvg from '../../assets/svg/SkyPlanBasicSvg';
import SkyPlanProSvg from '../../assets/svg/SkyPlanProSvg';
import UpgradePlanBannerSvg from '../../assets/svg/UpgradePlanBannerSvg';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Button } from 'antd';
import './upgradePlanTemplate.less';
import { CheckCircleFilled } from '@ant-design/icons';

interface Props {
  renderProPlan: boolean;
  upgradePlan_t: any;
  handleUpgradeOnClick: (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<{
    success: boolean;
  }>;
}

const UpgradePlanTemplate: React.FC<Props> = ({
  renderProPlan,
  upgradePlan_t,
  handleUpgradeOnClick,
}) => {
  const [isUpgradePlanLoading, setIsUpgradePlanLoading] = useState(false);
  const [sentSuccessfully, setSentSuccessfully] = useState(false);

  const bulletPointsArr = [
    upgradePlan_t.text_1,
    upgradePlan_t.text_2,
    upgradePlan_t.text_3,
    upgradePlan_t.text_4,
  ];

  const handleOnClick = () => {
    if (sentSuccessfully) {
      return;
    }
    handleUpgradeOnClick(setIsUpgradePlanLoading).then((res) => {
      setSentSuccessfully(res.success);
    });
  };

  return (
    <div className="upgrade-plan-template-wrapper">
      <div className="logo-wrapper">
        {renderProPlan ? <SkyPlanProSvg /> : <SkyPlanBasicSvg />}
      </div>
      <UpgradePlanBannerSvg className={'banner-image'} />

      <div className="inclusions">
        <h2 className="template-title">{upgradePlan_t.title}</h2>
        <p className="benefits-title-text">
          {upgradePlan_t.benefits_title_text}
        </p>
        {bulletPointsArr.map((line: string, index: number) => {
          return (
            <div key={index}>
              {index > 0 && <div className="horizontal-line" />}
              <p>
                <CheckCircleFilled
                  style={{
                    fontSize: '20px',
                    color: 'var(--ant-primary-color-active)',
                  }}
                />
                <span className="line">{line}</span>
              </p>
            </div>
          );
        })}
      </div>

      {!sentSuccessfully && (
        <Button
          className="btn-style-5 primary upgrade-btn"
          onClick={handleOnClick}
          loading={isUpgradePlanLoading}
          disabled={sentSuccessfully}
        >
          {upgradePlan_t.button_text}
        </Button>
      )}
    </div>
  );
};

export default UpgradePlanTemplate;
