import React from 'react';
import { PriceUnitLabels, PriceUnitTypes, PriceUnit } from './AttractionTypes';
import { MdFamilyRestroom, MdDirectionsCarFilled } from 'react-icons/md';
import { IoIosMan } from 'react-icons/io';
import { FaBaby, FaChild, FaTools } from 'react-icons/fa';
import { GrGroup } from "react-icons/gr";
import {
  DeploymentUnitOutlined
} from '@ant-design/icons';
import { TbSailboat } from "react-icons/tb";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface Props {
  type: PriceUnit | PriceUnitLabels;
}

const Icon = ({ type }: Props) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  let icon: any = false;
  let classAppend: string = '';

  switch (type) {
    case PriceUnitLabels.adult:
    case PriceUnit.perPerson:
    case PriceUnitLabels.senior:
    case PriceUnitLabels.student:
      icon = <IoIosMan size={32} />;
      break;
    case PriceUnitLabels.child:
    case PriceUnitLabels.children:
    case PriceUnitLabels.youth:
      icon = <FaChild size={25} />;
      break;
    case PriceUnitLabels.infant:
      icon = <FaBaby size={25} />;
      break;
    case PriceUnit.perVehicle:
      icon = <MdDirectionsCarFilled size={24} />;
      break;
    case PriceUnit.group:
      icon = <GrGroup size={24} />;
      break;
    case PriceUnit.family:
      icon = <MdFamilyRestroom size={24} />;
      break;
    case PriceUnit.perEquipment:
      icon = <FaTools size={24} />;
      break;
    case PriceUnit.package:
      icon = <DeploymentUnitOutlined size={24} />;
      break;
    case PriceUnit.perBoat:
      icon = <TbSailboat size={24} />;
      break;
    case PriceUnit.none:
      default:
      icon = null;
      // break;
      // icon = t?.[type] ?? type;
  }
  return icon ? (
    <span className={`ico-int ico-${type} ${classAppend}`}>{icon}</span>
  ) : <span>{t?.[type] ?? type}</span>;
};

export default Icon;
