import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlanIdType } from '@skygroup/shared/constants/agency';

interface Props {
  subscriptionObj: {
    id: number;
    payment_status: string;
    plan_id: PlanIdType;
  };
}
export const useSubscriptionPlanGuard = ({ subscriptionObj }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/select-subscription-plan');
  };

  const handleNewTab = () => {
    const src = `${process.env.REACT_APP_BO_BASE_URL}/select-subscription-plan`;
    window.open(src, '_blank');
  };

  const getUrl = () => {
    return `${process.env.REACT_APP_BO_BASE_URL}/select-subscription-plan`;
  };


  return { handleNavigate, handleNewTab, getUrl };
};
