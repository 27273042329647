const appConfig = {
    "siteConfig": {
        "defaultLang": "he_IL",
        "direction": "rtl",
        "languages": [
            {
                "code": "he_IL",
                "dir": "rtl",
                "name": "עברית",
            },
            {
                "code": "en_US",
                "dir": "ltr",
                "name": "English",
            },
            {
                "code": "uk_UA",
                "dir": "ltr",
                "name": "українська",
            },
            {
                "code": "fr_FR",
                "dir": "ltr",
                "name": " Français"
            },
            {
                "code": "ru_RU",
                "dir": "ltr",
                "name": "Русский"
            }
        ],
        "name": "Sky 360 - הבית לסוכני התיירות",
        "domain": "b2b.sky360.co.il",
        "defaultDate":  {
            "from": 30,
            "range": 4
        },
        "style": {
            "topClass": 'b2c-sky360'
        },
        defaultDeparture:"TLV",
        searchConfig: {
          "packages": {
              "ts_type": 2,
              "enable_calender_price_api": true,
              "favorite_locations":[
            ]
          },
            "sky_packages": {
                "ts_type": 1,
                "enable_calender_price_api": false,
                "favorite_locations": [
                ],
            },
            "flights": {
                defaultDeparture: {value:"TLV", label:"תל אביב"},
                favorite_locations: [
                  {value:"TLV", label:"תל אביב"},
                  // {value:"RHO", label:"רודוס"},
                  // {value:"ATH", label:"אתונה"},
                  // {value:"LCA", label:"לרנקה"},
              ]
            },
            "hotels": {
                "favorite_locations":[
                    ]
            }
        },
        "dateFormat":"DD/MM/YYYY",
        "logo":"/assets/images/sky360/sky360-logo.png",
        "logoSmall":"/assets/images/logoSmall.png",
        "boardBasis":[
            "full_board",
            "sleep_only",
            "breakfast_kidush",
            "all_include",
            "breakfast",
            "half_board",
            "half_board_kosher",
        ],
        "hotelStar":[
            '3', '4', '5'
        ],
        "destinations": {
            "local": [],
            "abroad":[
                {
                    "label": "טאבה הייטס - סיני ",
                    "value": 3419
                },
                {
                    "label": "בוקרשט - רומניה",
                    "value": 3421
                },
                {
                    "label": "קפריסין - לרנקה",
                    "value": "LCA"
                },
                {
                    "label": "לימסול - קפריסין",
                    "value": 3425
                },
                {
                    "label": "פרוטראס - קפריסין",
                    "value": 3428
                },
                {
                    "label": "אתונה - יוון ",
                    "value": 3400
                },
                {
                    "label": "לוטרקי - יוון ",
                    "value": 3418
                }
            ]
        },
        "occupancy": [
            { adult: 2, child: 0},
            { adult: 3, child: 0},
            { adult: 2, child: 1},
            { adult: 2, child: 2},
        ],
        "email": {
            "value": "opr@sky360.co.il",
            "title": "זמינים במייל",
        },
        "phone": {
            "value": "077-998-4698",
            "title": "התקשרו או כתבו לנו בוואטסאפ",
            "hours": "שעות פעילות 09:00-17:00"
        },
        "facebook":{
            "value":"https://www.facebook.com/familybreak.co.il/",
            "title":"פרגנו לנו בעוקב בפייסבוק"
        },
        "instagram":{
            "value":"https://www.instagram.com/familybreak.co.il/",
            "title":"פרגנו לנו בעוקב באינסטגרם"
        },
        "whatsapp": {
            "phone":"077-998-4698",
            "message":"",
        },
        "companyInfo": {
            "title":"SKY 360",
            "value":"סקיי 360 הינה חברה העוסקת בתחום תעופה,תיירות נכנסת ופיתוח מערכות ענן הנותנות פתרונות טכנולוגיים בעולם התיירות. לפרטים נוספים בקרו באתר הבית https://www.sky360.co.il/",
            "address": "ויצמן 24, נתניה"
        }
    },
    "theme": {
        "primary":"#002854",
        "secondary": "#0166ff",
        "tertiary": "#c2c2c2",
        "background": "#fff",
        "button": "btn-style-5",
        "fontFamily": "Heebo",
        "title": "title-style-2"
    },
    "layouts": {
        "default": {
            "topModules": [
                "Header",
            ],
            "bottomModules": [
                "Footer",
            ]
        },
        "noFooter": {
            "topModules": [

            ],
            "bottomModules": [
                "Footer"
            ]
        },
        "empty": {
            "topModules": [

            ],
            "bottomModules": [

            ]
        }
    },
    "pages": [
        {
            "path": "/",
            "modules": [
                "ProductFilterMainPage",
                "FlightList",
                // "DewstinationListLight",
                // "DestinationList",
                "PackageListView",
                // "BannerLargePalms",
                // "BannerLarge",
                // "PackageListViewFilters",
                // "BannerLargeGirl",
                // "PackageListViewNextWeek",
                // "Socials"
            ],
            "name": "Main Page",
            // FLAG
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/prom/:promSecret/package/:id",'/package/view','/package/:id', "/p/:alias","/event/:alias/","/event/:alias/:affiliate","/package/:id/:affiliate","/prom/:promSecret/package/:id/:affiliate"],
            "modules": [
                "PackagePage"
            ],
            "name": "Package Info",
            "type": "packagePage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/prom/:promSecret", "/prom/:promSecret/:affiliate"],
            "modules": [
                "PromoPage",
            ],
            "name": "Main Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/room/:id",
            "modules": [
                "ImgCarousel",
                "SearchDatePicker",
                "RoomInfo",
                "OrderNow",
                "Activities",
                "RoomGeneralDetails"
            ],
            "name": "Room Info",
            "type": "roomPage",
            "layout": "empty",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/packages",
            "modules": [
                "ProductFilterPackageSearchPage"
            ],
            "name": "Packages Search",
            "type": "searchResultPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/flights","/flights/dynamic"],
            "modules": [
                "ProductFilterFlightSearchPage"
            ],
            "name": "Packages Search",
            "type": "searchResultPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/hotels",
            "modules": [
                "ProductFilterHotelSearchPage"
            ],
            "name": "Packages Search",
            "type": "searchResultPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/hotels",
            "modules": [
                "SearchDatePickerHotelPage"
            ],
            "name": "Packages Search",
            "type": "searchResultPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        // {
        //     "path": "/hotels/:hotelId",
        //     "modules": [
        //         "PackageDetails",
        //         "PackageRoomPricing",
        //     ],
        //     "name": "Package Info",
        //     "type": "HotelPage",
        //     "layout": "default",
        //     "seo": {
        //         "title": "",
        //         "desc": ""
        //     }
        // },
        {
            "path": "/checkout/:id",
            "modules": [
                "Checkout",
            ],
            "name": "Checkout",
            "type": "checkoutPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/order/manage",
            "modules": [
                "ManageOrder",
            ],
            "name": "Manage Order",
            "type": "orderPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/contact",
            "modules": [

                "ProductFilterMainPage",
                "ContactUs",
            ],
            "name": "ContactUs",
            "type": "ContactUsPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/about",
            "modules": [
                "ProductFilterMainPage",
                "PageContentAboutUs",
            ],
            "name": "AboutUs",
            "type": "AboutUsPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/takanon",
            "modules": [
                "ProductFilterMainPage",
                "PageContentTakanon",
            ],
            "name": "Takanon",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/passenger-info",
            "modules": [
                "ProductFilterMainPage",
                "PageContentPassenger",
            ],
            "name": "PassengerInfo",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/privacy-info",
            "modules": [
                "ProductFilterMainPage",
                "PageContentPrivacy"
            ],
            "name": "ContentPrivacy",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/accessibility-info",
            "modules": [
                "ProductFilterMainPage",
                "PageContentAccessibility"
            ],
            "name": "ContentPrivacy",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/hotel/:id/",
            "modules": [
                "HotelPage",
            ],
            "name": "Package Info",
            "type": "hotelPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            },

        },
        {
            "path": "/destinations",
            "modules": [
                "SearchDatePickerMainPage",
                "DestinationList",
            ],
            "name": "Destination Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path":"/flight/view/:type/:flightId",
            "modules": [
                // "SearchDatePickerFlightViewPage",
                "ProductFilterViewFlightPage",
                "ViewFlight",
            ],
            "name": "NotFound404",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/404",
            "modules": [
                "NotFound404",
                // "SearchDatePickerMainPage",
            ],
            "name": "NotFound404",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "seo": {
                "desc": "",
                "title": ""
            },
            "name": "Attractions Search",
            "path": [
                "/attractions",

            ],
            "type": "searchResultPage",
            "layout": "default",
            "modules": [
                "ProductFilterAttractionsSearchPage"
            ]
        },
        {
            "seo": {
                "desc": "",
                "title": ""
            },
            "name": "Attraction Info",
            "path": [
                "/attractions/:id",
            ],
            "type": "attractionPage",
            "layout": "default",
            "modules": [
                "AttractionPage"
            ]
        },
    ],
    "modulesConfigs": {
        "Header": {
            "links": [
                {
                    "text": "עמוד הבית",
                    "url": "/"
                },
                {
                    "text": "חבילות נופש",
                    "url": "/packages"
                },
                {
                    "text": "טיסות",
                    "url": "/flights"
                },
                {
                    "text": "מלונות",
                    "url": "/hotels"
                },
                {
                    "text": "מי אנחנו",
                    "url": "https://www.sky360.co.il"
                },
                // {
                //     "text": "על הצימר",
                //     "url": "/bandb"
                // },
                // {
                //     "text": "החדרים",
                //     "url": "/rooms"
                // },
                // {
                //     "text": "אטרקציות",
                //     "url": "/attractions"
                // },
                {
                    "text": "צור קשר",
                    "url": "/contact"
                }
            ],
            "theme": {
            },
            "set": "HeaderSet3",
            "logo": "/assets/images/sky360/sky360-logo.png",
            "widgetLangs": {
                "isActive": true,
            },
            "widgetSocial": {
                "isActive": false,
                "socials": [
                    {
                        "key": "instagram",
                        "value": "https://www.instagram.com/sky360.co.il/",
                    }, {
                        "key": "facebook",
                        "value": "https://www.facebook.com/sky360.co.il/"
                    }, {
                        "key": "whatsapp",
                        "value": "whatsapp://send?text=ברוכים הבאים למענה של סקיי 360!&phone=+972779984698",
                    }
                ],
            },
            "module": "Header"
        },
        "Footer": {
            "columns": [
                {
                    "header": "צור קשר",
                    "links": [
                        {
                            "text": "כתבו לנו",
                            "url": "/contact"
                        },
                        {
                            "text": "מי אנחנו?",
                            "url": "https://www.sky360.co.il/"
                        },
                        {
                            "text": "תקנון האתר",
                            "url": "/takanon"
                        },
                        {
                            "text": "מדיניות פרטיות",
                            "url": "/privacy-info"
                        },
                        {
                            "text": "הצהרת נגישות",
                            "url": "/accessibility-info"
                        },
                        {
                            "text": "מידע כללי לנוסע",
                            "url": "/passenger-info"
                        }
                    ]
                },
            ],
            "set": "FooterSet2",
            "module": "Footer",
            "widgetScrollToTop": {
                "isActive": true
            },
            "logo": "/assets/images/sky360/sky360-logo.png",
            "theme": {
                "background": "#00CCFF",
                "tertiary": "#000"
            }
        },
        "SearchDatePickerMainPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSet2",
            "showSearchResults":{
                "isActive":false
            },
            "searchOptions": "packages,flights,hotels,attractions",
            "backgroundImg": "/assets/images/searchDatepickerPackground.jpg",
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "DestinationListLight":{
            "set": 'DestinationListLightSet3',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        "FlightList":{
            // "set": "PackageListViewSet2",
            // "theme": {
            //     "background": "#00ccff33",
            //     "secondary": "#00ccff",
            // },
            "module": "FlightList",
            // "backgroundType":"type1",
            "defaultFilters":{
                "limit":8,
                "from_date": "23/06/2024",
                "return_date": "27/06/2024",
                "departure": "TLV",
                "destination": "LCA",
                "adult": "1",
                "prvmd": "0",
                "result_cache": "1",
                "unique": "1",
                "ts_type": "2"
            },
            "title": 'טיסות ברגע האחרון',
        },
        "DestinationList":{
            "set": 'DestinationListSet1',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        "BannerLarge": {
            "module": "BannerLarge",
            "set": "BannerSet1",
            "img": "/assets/images/famflight.png",
            "button": false,
            "contentWidth": true,
            "backSize": "contain",
        },
        "BannerLargeGirl": {
            "module": "BannerLarge",
            "set": "BannerSet2",
            "key": "BannerLargeGirl",
            "img": "/assets/images/baby-girl.png",
            "button": false,
            "textLine1":"נופש בחגים זה אנחנו.",
            "theme":{
                background:"#00CCFF"
            }
        },
        "HotelPage": {
            "PackageDetailsConfig": {
               "widgetFlights": {
                 "isActive": false,
                 "widgetSet": 'FlightsSet2',
               },
               widgetHotelInfo: {
                 "isActive": true,
                 "widgetSet": 'HotelInfoSet2',
               },
               widgetActivities: {
                "theme": {
                    "button": "btn-style-5",
                    "background": "#fff",
                    "tertiary": "#000000"
                },
                "isActive": true,
                "widgetSet": 'ActivitiesSet2',
            },
               showDates: false,
             },
             RoomPricingConfig : {
            //    "set": 'RoomPricingSet2',
               "set": 'RoomPricingSet1',
             }
       },
        "PackagePage": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "text_color": "#fff",
                "background": "#00ccff33"
            },
            "PackageDetailsConfig": {

                "widgetFlights": {
                    "isActive": true,
                    "widgetSet": 'FlightsSet2',
                },
                widgetHotelInfo: {
                    "isActive": true,
                    "widgetSet": 'HotelInfoSet1',
                },
                widgetActivities: {
                    "theme": {
                        "button": "btn-style-5",
                        "background": "#fff",
                        "tertiary": "#000000"
                    },
                    "isActive": true,
                    "widgetSet": 'ActivitiesSet2',
                },
                showDates: false,
            },
            "RoomPricingConfig": {
                "set": 'RoomPricingSet1',
                "theme": {
                    "button": "btn-style-5",
                    "tertiary": "black",
                    "background": "#00ccff33"
                },
            }
        },
        "BannerLargePalms": {
            "module": "BannerLarge",
            "set": "BannerSet3",
            "key": "BannerLargePalms",
            "img": "/assets/images/bannerPalms.png",
            "contentWidth": true,
            "button": true,
            "buttonText": "תנו קפיצה לקיץ",
            "textLine1":"נופש בחגים זה אנחנו.",
        },
        "ImgCarousel": {
            "carousel": [
                {
                    "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
                    "text": "צימר רמת הגולן המקום המושלם."
                },
                {
                    "img": "https://cdn2.hubspot.net/hubfs/439788/Blog/Featured%20Images/Best%20Hotel%20Website%20Designs.jpg",
                    "text": "צימר רמת הגולן המקום המושלם."
                }
            ],
            "theme": {
                "title": "title-style-1"
            },
            "module": "ImgCarousel"
        },
        "DatesSuggestion": {
            "buttonText": "לעוד דילים טובים ›",
            "url": "#",
            "title": "תאריכי חופשה",
            "titleLineTwo": "פופולאריים",
            "theme": {
                "title": "title-style-2"
            },
            "module": "DatesSuggestion"
        },
        "Description": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "description": "המקום המושלם לחופש, שקט והנאה למשפחות.",
            "theme": {
                "title": "title-style-2"
            },
            "module": "Description"
        },
        "ContactUs": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "set":"ContactUsSet2",
            "title":"צרו איתנו קשר.",
            "titleLineTwo": "צרו איתנו קשר.  נשמח לעזור לכם להזמין חופשה מושלמת.",

            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "ContactUs"
        },
        "PageContentAboutUs": {
            "title":"צרו איתנו קשר.",
            "set":"PageContentSet1",
            "desc_id": 3,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentTakanon": {
            "title":"תקנון האתר",
            "set":"PageContentSet1",
            "desc_type": "terms",
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentPassenger": {
            "title":"מידע לנוסע",
            "set":"PageContentSet1",
            "desc_id": 2,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentPrivacy": {
            "title":"מדיניות פרטיות",
            "set":"PageContentSet1",
            "desc_type": "privacy",
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentAccessibility": {
            "title":"הצהרת נגישות",
            "set":"PageContentSet1",
            "desc_type": "accessibility",
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        'ProductFilterMainPage': {
            "module": "SwitchDatePicker",
            'searchOptions': ['flights', 'packages', "hotels"],
            "showSearchResults": {
                isActive: false
            },
            "backgroundImg": "/assets/images/sky360/main-back.jpg"
        },
        'ProductFilterFlightSearchPage':{
            "module": "SwitchDatePicker",
            'searchOptions':['flights'],
            "backgroundImg": "/assets/images/sky360/main-back.jpg",
            "showSearchResults":{
                isActive:true
            },
            "theme":{
                "primary":"#0a2a59",
                "secondary":"#53cff7",
                'tertiary':"#7d7f8b"
            }
        },
        'ProductFilterHotelSearchPage':{
            "module": "SwitchDatePicker",
            'searchOptions':['hotels'],
            "backgroundImg": "/assets/images/sky360/main-back.jpg",
            "showSearchResults":{
                isActive:true
            },
        },
        'ProductFilterViewFlightPage':{
            "module": "SwitchDatePicker",
            'searchOptions':['flights'],
            "backgroundImg": "/assets/images/sky360/main-back.jpg",
            "showSearchResults":{
                isActive:false
            },
            "showFilters": {
                isActive: false
            },
        },
        'ProductFilterPackageSearchPage':{
            "module": "SwitchDatePicker",
            'searchOptions':['packages'],
            "backgroundImg": "/assets/images/sky360/main-back.jpg",
            "showSearchResults":{
                isActive:true
            },
            "showFilters": {
                isActive: false
            }
        },
        'SearchDatePickerWithSwitcher':{
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchOptions':[ 'packages', 'flights', "hotels","specialPackage"],
            "set": "SearchDatePicketSwitcher",
            'searchResultType' : "packages",

            "autoSearch":false,
            "showSearchResults":{
                isActive:false
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        'SearchDatePickerFlightViewPage':{
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33",
            },
            'searchOptions':[],
            "set": "SearchDatePicketSwitcher",
            'searchResultType' : "flights",
            "autoSearch":false,
            "showSearchResults":{
                isActive:false
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "SearchDatePickerSearchPackagePage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            "searchResultType": "packages",
            'searchOptions': [],
            "set": "SearchDatePickerPackageIndex",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "SearchDatePickerFlightPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchResultType' : "flights",
            'searchOptions': [],
            'autoSearch':true,
            "set": "SearchDatePicketSwitcher",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "flightSummary":{
                isActive: true
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "SearchDatePickerHotelPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchOptions':[],
            "set": "SearchDatePicketSwitcher",
            'searchResultType' : "hotels",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "flightSummary":{
                isActive: true
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "NotFound404":{
            "image":"/assets/images/404.png",
            "title":'אופס.. הדף שחיפשת לא קיים',
            "redirect_title":'הנך מועבר לדף הבית',
            "redirect_seconds":7000,
        },
        "RoomInfo": {
            "theme": {
                "title": "title-style-1"
            },
            "set": "RoomInfoSet1",
            "widgetRoomDetails": {
                "isActive": true
            },
            "widgetRoomFacilities": {
                "isActive": true
            },
            "widgetRoomPictures": {
                "isActive": true
            },
            "facilities": [
                "TV",
                "Telephone",
                "Card",
                "Bed",
                "Radio",
                "BreakFast",
                "Porter",
                "FiveStar",
                "Juice",
                "HouseKeeping",
                "DoNotDisturb",
                "Hostess"
            ],
            "module": "RoomInfo"
        },
        "OrderNow": {
            "theme": {
                "background": "#42595d"
            },
            "module": "OrderNow"
        },
        "ActivitiesSuggestion": {
            "buttonText": "לכל האטרקציות ›",
            "url": "#",
            "title": "אטרקציות וחוויות",
            "titleLineTwo": "בקרבת מקום",
            "theme": {
                "title": "title-style-3"
            },
            "module": "ActivitiesSuggestion"
        },
        "SearchDatePicker": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSmallPackage",
            "showSearchResults":{
                "isActive":true
            },
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "PromoPage": {
            "module": "PromoPage",
            "packageListConfig": {
                "set": "PackageListViewSet2",
                "stylesName": "packageBox3",
                "backgroundType":"type1",
                "widgetFilterMenu": {
                    "isActive": false,
                },
                "widgetMorePackages": {
                    "isActive": false
                },
                "widgetPackageBoxHeader": {
                    "isActive": true,
                    "title": ""
                }
            },
        },
        "PackageListView": {
            "set": "PackageListViewSet2",
            "boxSet": "packageBoxWithGallery",
            // "boxSet": "packageBoxWithGalleryLarge",
            "theme": {
                "background": "#00ccff33",
                "secondary": "#00ccff",
            },
            "module": "PackageListView",
            "backgroundType":"type1",
            "defaultFilters":{
                // "staticFilter": "type=indexList&tid=1",
                "limit": 9,
                "result_cache": "1",
                "unique": "1",
                "destination": "RHO,LCA,HER",
                "dates": {
                    "from": 1,
                    "range": 14,
                },
                "from_date": "23/06/2024",
                "return_date": "27/06/2024",
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"הדילים החמים - יולי אוגוסט משפחות!"
            }
        },
        "PackageListViewFilters": {
            set: "PackageListViewSet2",
            "stylesName": "packageBox3",
            // "set": "packageBoxWithGalleryLarge",
            "module": "PackageListView",
            "theme": {
                "button": "btn-style-5",
            },
            "backgroundType":false,
            "widgetFilterMenu": {
                "isActive": true,
                "listFilters": [
                    {
                        "name": "מאמיה",
                        "filters": {
                            "dates": "14/07/2023-01/09/2023",
                            "limit": "6",
                            "destination":"3423"
                        }
                    },
                    {
                        "name": "קפריסין הצפונית",
                        "filters": {
                            "dates": "01/05/2023-01/09/2023",
                            "limit": "6",
                            "destination":"3396"
                        }
                    },
                    {
                        "name": "מחירים מיוחדים יולי",
                        "filters": {
                            "source": "alp",
                            "feature": "cruise",
                            "dates": "01/07/2023-01/08/2023",
                            "limit": "12",
                        }
                    },
                    {
                        "name": "חופשה עם הילדים",
                        "filters": {
                            "dates": "01/08/2023-01/09/2023",
                            "limit": "12",
                        }
                    }

                ],
                "title":"חבילות נופש לכל המשפחה",
                "titleLineTwo":"ביעדים והמלונות שלנו!"
            },
            "widgetMorePackages": {
                "isActive": true
            },
            "widgetPackageBoxHeader": {
                "isActive": false
            }

        },
        "PackageListViewNextWeek": {
            "set": "PackageListViewSet2",
            "stylesName": "packageBox4",
            "module": "PackageListView",
            "backgroundType":false,
            "defaultFilters":{
                "staticFilter": "type=upcoming",
                "limit":3,
                "dates": {
                    "from": 7,
                    "range": 21
                }
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"כמה אתם ספונטנים?"
            }
        },
        "PackageDetails": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "#000000"
            },
            "widgetFlights": {
                "isActive": true,
                "widgetSet": "FlightsSet2",
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet2",
            },
            "widgetActivities": {
                "isActive": true,
                "widgetSet": "ActivitiesSet2",
            }

        },
        "HotelDetails": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet1",
            },
            "widgetDatePicker": {
                "isActive": true,
                "widgetSet": "HotelDatePickerSet1",
            },
        },
        "RoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
            },
        },
        "HotelRoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
        },
        "PackageRoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
            },
        },
        "Activities": {
            "theme": {
                "tertiary": "black"
            },
            "set": "ActivitiesSet1",
            "module": "Activities"
        },
        "ViewFlight":{
            "theme":{
                "tertiary": "#7d7f8b",
                "background":"#E1F8FC",
                "secondary":"#53cff7"
            }
        },
        "ProductFilterAttractionsSearchPage": {
            "theme": {
                "primary": "#0a2a59",
                "tertiary": "#7d7f8b",
                "secondary": "#53cff7",
                "background":"#f2f3f5"
            },
            "module": "SwitchDatePicker",
            "backgroundImg": "/assets/images/sky360/main-back.jpg",
            "searchOptions": [
                "attractions"
            ],
            "showSearchResults": {
                "isActive": true
            }
        },
    }
};
export default appConfig;