import { Modal, Spin, Tooltip } from 'antd';
import React, { Suspense, useMemo, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import './productPreviewComponent.less';

interface Props {
  productPath: string;
  label: string | JSX.Element;
  componentClassName?: string;
  labelClassName?: string;
}

const ProductPreviewComponent: React.FC<Props> = ({
  productPath,
  label,
  componentClassName,
  labelClassName,
}) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );
  const [openedModalUrl, setOpenedModalUrl] = useState<string | null>(null);

  const productUrl = useMemo(() => {
    if (!productPath) {
      return null;
    }
    const baseUrl = window.location.origin;
    const searchParams = new URLSearchParams(productPath);
    searchParams.append('prvmd', '1');
    const url = new URL(baseUrl + productPath);
    url.search = searchParams.toString();
    return url.toString();
  }, [productPath]);

  return (
    <>
      <div
        className={`product-preview-wrapper ${componentClassName ?? ''}`}
        onClick={() => setOpenedModalUrl(productUrl)}
      >
        <Tooltip title={label}>
          <span className={`cart-title ${labelClassName ?? ''}`}>{label}</span>
        </Tooltip>
        <span className="view-product-label-wrapper">
          <FaEye size={24} />
          <span>{t.viewProduct}</span>
        </span>
      </div>

      <Modal
        open={!!openedModalUrl}
        className="product-preview-modal"
        onCancel={() => {
          setOpenedModalUrl(null);
        }}
        closable
        maskClosable={false}
        footer={null}
        destroyOnClose
      >
        <Suspense
          fallback={
            <Spin className="center lazy-loader-spinner" size="large" />
          }
        >
          {openedModalUrl ? (
            <iframe key={openedModalUrl} src={openedModalUrl} />
          ) : null}
        </Suspense>
      </Modal>
    </>
  );
};

export default ProductPreviewComponent;
