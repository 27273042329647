import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

export enum PriceView {
  Gross = 'gross',
  Net = 'net',
}

const useGetGrossPrice = () => {
  const agentInfo = useSelector((state: RootState) => state.config.agentInfo);
  const price_view = agentInfo?.agent?.price_view;

  const getGrossPrice = (price: number, commission?: number, decimals = 0) => {
    if (price_view !== PriceView.Gross || !commission || commission >= 100){
      return Number(price?.toFixed(decimals));
    }

    const grossPrice = Number(price / (1 - commission / 100))?.toFixed(decimals);
    return Number(grossPrice);
  };

  return getGrossPrice;
};

export default useGetGrossPrice;
