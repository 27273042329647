export enum PlanIdType {
  LIGHT = 'agent_360_light',
  REGULAR = 'agent_360',
  PRO = 'agent_360_pro',
}

// import { PlanIdType } from '@skygroup/shared/constants/agency';

export enum RegStatusType {
  STATUS_PLAN = 'plan_selection',
  STATUS_AGENCY_INFO = 'agency_info',
  STATUS_PAYMENT = 'payment',
  STATUS_CONTRACT_TO_SIGN = 'contract_to_sign',
  STATUS_COMPLETED = 'completed',
}
