import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import './shoppingCart.less';
import ShoppingCartHeader from './widgets/ShoppingCartHeader';
import ShoppingCartEmpty from './widgets/ShoppingCartEmpty';
import { isEmpty } from 'lodash';
import { AiFillCloseCircle } from 'react-icons/ai';
import DiscountCode from './widgets/DiscountCode';
import ShoppingCartSubsidize from './widgets/ShoppingCartSubsidize';
import { Modal, Spin, Tooltip } from 'antd';
import useHandleCheckout from './hooks/useHandleCheckout';
import { MainProductType } from './interfaces';
import {
  deleteAttractionsFromCart,
  Product,
  setWholeCart,
} from './ShoppingCartSliceV2';
import AgentActions from './agentActions/AgentActions';
import ShoppingCartActivity from './products/ShoppingCartActivity/ShoppingCartActivity';
import ShoppingCartRoom from './products/ShoppingCartRoom/ShoppingCartRoom';
import ShoppingCartPackage from './products/ShoppingCartPackage/ShoppingCartPackage';
import ShoppingCartHotel from './products/ShoppingCartHotel/ShoppingCartHotel';
import ShoppingCartFlight from './products/ShoppingCartFlight/ShoppingCartFlight';
import ShoppingCartFlightPackage from './products/ShoppingCartFlightPackage/ShoppingCartFlightPackage';
import useInitializeCurrentCustomer from './hooks/useInitializeCurrentCustomer';
import { MdWarning } from 'react-icons/md';
import useUpdateCommissionNeeded from './hooks/useIsUpdateCommissionNeeded';
import { useNavigate } from 'react-router-dom';
import CartNotValid from './widgets/CartNotValid';
import AddUserModal from './agentActions/AddUserModal/AddUserModal';
import ShoppingCartOneWayFlight from './products/ShoppingCartOneWayFlight/ShoppingCartOneWayFlight';
import { packageLink } from '../../utils/LinkGenerator';
import useCssVariables from '../../hooks/useCssVariables';
import ButtonWithIcon from '../../components/Common/ButtonWithIcon';
import { FaRegSave } from 'react-icons/fa';
import { updateCommission } from '../Checkout/CheckoutApis';
import useCleanUpLocalStorage from './hooks/useCleanUpLocalStorage';
import ShoppingCartAttraction from './products/ShoppingCartAttraction/ShoppingCartAttraction';

interface Props {
  pageType: string;
  open?: boolean;
  closeFromHeader?: () => void;
  showDiscounCodeOrIntegrationInfo?: boolean;
  isMobileView?: boolean;
}

const ShoppingCartV2 = ({
  pageType,
  open,
  closeFromHeader,
  showDiscounCodeOrIntegrationInfo = false,
  isMobileView = false,
}: Props) => {
  const isCheckoutPage = pageType === 'checkout' || pageType === 'manage-order';
  const isMangeOrderPage = pageType === 'manage-order';
  const [shoppingCartOpen, setShoppingCartOpen] = useState(false);
  const { shoppingCartTranslations: t, roomPricing: t_roomPricing } =
    useSelector((data: RootState) => data.config.translations);

  const { appliedSubsidize } = useSelector(
    (state: RootState) => state.shoppingCart
  );
  const { ShoppingCartV2, agent_commission } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );
  const [addUserModal, setAddUserModal] = useState({
    open: false,
    customer_secret: '',
  });

  const isUpdateCommissionNeeded = useUpdateCommissionNeeded();

  useCleanUpLocalStorage();

  const { agentInfo, config } = useSelector((data: RootState) => data.config);

  useEffect(() => {
    if (!!ShoppingCartV2?.orderId && !isEmpty(ShoppingCartV2?.orderId)) {
      setShoppingCartOpen(true);
    }
  }, [ShoppingCartV2]);

  const navigate = useNavigate();
  const checkout = useHandleCheckout(isCheckoutPage);
  const handleCheckout = () => {
    if (!!ShoppingCartV2?.orderId && !ShoppingCartV2?.isSaved) checkout();
    if (!!ShoppingCartV2?.orderId && ShoppingCartV2?.isSaved)
      navigate(`order/manage?id=${ShoppingCartV2?.orderSecret}`);
  };

  const { settings } = useSelector((state: RootState) => state.shoppingCart);

  const showCloseButton = useMemo(
    () =>
      (open || shoppingCartOpen) &&
      pageType !== 'checkout' &&
      pageType !== 'manage-order',
    [open, pageType, shoppingCartOpen]
  );

  const freePackage = useMemo(() => {
    if (pageType === 'manage-order' && settings?.price_option_type === 'free')
      return true;
    if (settings?.price_option_type === 'free') return true;
    return false;
  }, [settings, pageType]);

  const className = settings?.flight_only ? 'flight-only' : '';

  const memoizedIsUpdateNeeded = useMemo(() => {
    return isUpdateCommissionNeeded();
  }, [isUpdateCommissionNeeded]);

  const generateCssVariables = useCssVariables();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!!ShoppingCartV2?.orderId) {
      dispatch(
        setWholeCart({
          ...ShoppingCartV2,
          isSaved: !isUpdateCommissionNeeded(),
        })
      );
    }
  }, [memoizedIsUpdateNeeded]);

  useInitializeCurrentCustomer();

  const [isUpdateCommissionLoading, setIsUpdateCommissionLoading] =
    useState(false);
  const handleUpdateCommission = async (e: any) => {
    e.stopPropagation();
    await updateCommission(
      {
        ...ShoppingCartV2,
        agent_commission: {
          ...agent_commission,
          is_commission_per_order: 1,
        },
      },
      ShoppingCartV2?.orderSecret ?? '',
      setIsUpdateCommissionLoading
    );
    dispatch(
      setWholeCart({
        ...ShoppingCartV2,
        agent_commission: {
          ...agent_commission,
        },
      })
    );
  };

  const handleDeleteAttraction =  (productIdentifier: string, subProductId: string) => {
    dispatch(
      deleteAttractionsFromCart({
        attractionIdentifier: productIdentifier,
        attractionTicketId: subProductId,
      })
    );
  };

  // this function is render each product ( * also the sub products)
  const ProductRenderer = (
    product: Product,
    identifier?: string,
    isTopLevel = false,
    isSameDates = false
  ) => {
    switch (product?.type) {
      case MainProductType.Activity:
        return (
          <ShoppingCartActivity
            freePackage={freePackage}
            isCheckoutPage={isCheckoutPage}
            product={product}
          />
        );
      case MainProductType.Attraction:
        return (
          <ShoppingCartAttraction
            product={product}
            identifier={identifier as string}
            handleDeleteAttraction={handleDeleteAttraction}
            isCheckoutPage={isCheckoutPage}
          />
        );
      case MainProductType.Flights:
        return (
          <ShoppingCartFlightPackage
            product={product}
            isCheckoutPage={isCheckoutPage}
          />
        );
      case MainProductType.oneWayFlights:
        return (
          <ShoppingCartOneWayFlight
            product={product}
            isCheckoutPage={isCheckoutPage}
          />
        );
      case MainProductType.Flight:
      case MainProductType.oneWayFlight:
        return <ShoppingCartFlight product={product} />;
      case MainProductType.Room:
        return (
          <ShoppingCartRoom
            freePackage={freePackage}
            product={product}
            isCheckoutPage={isCheckoutPage}
            identifier={identifier}
            isSameDates={isSameDates}
          />
        );
      case MainProductType.Hotel:
        return (
          <ShoppingCartHotel
            ProductRenderer={ProductRenderer}
            product={product}
            identifier={identifier}
            isTopLevel={isTopLevel}
          />
        );
      case MainProductType.Package:
        return (
          <ShoppingCartPackage
            subProducts={product.subProducts || []}
            product={product}
            ProductRenderer={ProductRenderer}
            identifier={identifier}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      id="ShoppingCart"
      className={
        'shopping_cart ' +
        className +
        (agentInfo?.agentMode ? ' shopping_cart-agent' : '') +
        (shoppingCartOpen || isMobileView ? ' cart-open' : '')
      }
    >
      {generateCssVariables('#ShoppingCart', config?.theme)}

      {showCloseButton && (
        <AiFillCloseCircle
          size={30}
          className="close"
          onClick={() => {
            closeFromHeader && closeFromHeader();
            setShoppingCartOpen(false);
          }}
          id="btn-close-cart"
        />
      )}
      <ShoppingCartHeader
        checkout={isCheckoutPage}
        setShoppingCartOpen={setShoppingCartOpen}
        shoppingCartOpen={shoppingCartOpen || Boolean(open)}
        setAddUserModal={setAddUserModal}
      />
      <AddUserModal
        addUserModal={addUserModal}
        setAddUserModal={setAddUserModal}
      />

      {(shoppingCartOpen || Boolean(open)) &&
        (ShoppingCartV2?.cartNotValid ? (
          <CartNotValid />
        ) : (
          <>
            {!ShoppingCartV2?.orderId ? (
              <ShoppingCartEmpty />
            ) : (
              <>
                <div className="content">
                  {agentInfo.agentMode && (
                    <AgentActions
                      addUserModal={addUserModal}
                      setAddUserModal={setAddUserModal}
                      isMangeOrderPage={isMangeOrderPage}
                    />
                  )}
                  {ShoppingCartV2?.products.map((product) => {
                    return (
                      <React.Fragment key={product.identifier}>
                        {ProductRenderer(product, product.identifier, true)}
                      </React.Fragment>
                    );
                  })}

                  {appliedSubsidize?.length > 0 && <ShoppingCartSubsidize />}
                </div>
              </>
            )}

            {settings?.show_coupon_code && showDiscounCodeOrIntegrationInfo && (
              <DiscountCode
                t={t}
                shoppingCart={ShoppingCartV2 as any}
                pageType={pageType}
              />
            )}
            {
              <div className="footer">
                <Tooltip
                  title={
                    !ShoppingCartV2?.orderId ? t.shoppingCartButtonTooltip : ''
                  }
                >
                  <div
                    className="btn-style-5"
                    onClick={!isCheckoutPage ? handleCheckout : undefined}
                  >
                    {!freePackage ? (
                      <>
                        <ul className={'total-cart-pricing'}>
                          {agentInfo?.agentMode &&
                            !!ShoppingCartV2?.orderId && (
                              <li className={'total-agent-comm'}>
                                <div>{t.totalAgentComm}</div>
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: 20,
                                    alignItems: 'baseline',
                                  }}
                                >
                                  {isUpdateCommissionNeeded() &&
                                    ShoppingCartV2?.orderSecret &&
                                    !ShoppingCartV2?.isSaved && (
                                      <div className="update-commission">
                                        <Tooltip title={t.commissionNotSaved}>
                                          <ButtonWithIcon
                                            onClick={handleUpdateCommission}
                                            loading={isUpdateCommissionLoading}
                                            // icon={<MdWarning color="yellow" />}
                                            icon={<FaRegSave />}
                                            text={t.updateCommission}
                                            className="btn-style-1"
                                          ></ButtonWithIcon>
                                        </Tooltip>
                                      </div>
                                    )}

                                  {/* {(
                                Number(ShoppingCartV2?.total_price) -
                                ShoppingCartV2?.total_net_price
                              ).toFixed(2)} */}
                                  {/* Math.ceil( */}
                                  {(
                                    Number(ShoppingCartV2?.total_price) -
                                    ShoppingCartV2?.total_net_price
                                  ).toFixed(2)}
                                  {String.fromCharCode(160)}
                                  {ShoppingCartV2?.currency_sign}
                                </div>
                              </li>
                            )}
                          <li>
                            <div>
                              {t.totalPrice}
                              {String.fromCharCode(160)}
                              <span className="currency-sign">
                                {ShoppingCartV2?.currency_sign}
                              </span>
                            </div>
                            <div>
                              {ShoppingCartV2?.total_price?.toFixed(0)}
                              {String.fromCharCode(160)}
                              {ShoppingCartV2?.currency_sign}
                            </div>
                          </li>
                        </ul>
                      </>
                    ) : (
                      <div className="free-package">{t.placeOrder}</div>
                    )}
                  </div>
                </Tooltip>
                {!!ShoppingCartV2?.orderId &&
                !isCheckoutPage &&
                !freePackage ? (
                  <div
                    className="move-to-checkout"
                    onClick={!isCheckoutPage ? handleCheckout : undefined}
                  >
                    {t.proceedToPayment}
                  </div>
                ) : null}
              </div>
            }
          </>
        ))}
    </div>
  );
};

export default ShoppingCartV2;
