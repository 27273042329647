import { Modal, Spin } from 'antd';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import importComponents from '../../utils/importComponents';
import { ModuleNames } from '../../interfaces';
import './layout.less';
import useCssVariables from '../../hooks/useCssVariables';
import classPriority from '../../utils/classPriority';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import EditComponent from '../../utils/EditComponent';
import EditLayoutComponent from '../../utils/EditLayoutComponent';
import { useSubscriptionPlanGuard } from '@skygroup/shared/components/subscription-plan-guard/useSubscriptionPlanGuard';
import { setIsUpgradePlanModalOpen } from '../../store/config';
import UpgradePlanTemplateModal from '@skygroup/shared/components/subscription-plan-guard/UpgradePlanTemplateModal';
import { PlanIdType } from '@skygroup/shared/constants/agency';
import { upgradeRequestApi } from '../AgentUserBanner/AgentApi';
import ModelApiMessage, {
  ApiStatus,
  ApiStatusMessage,
} from '@skygroup/shared/components/crud-helpers/ModelApiMessage';

interface Props {
  pageComponents: string[];
  layout: string;
  isPreviewMode?: boolean;
}

const Layout = ({ pageComponents, layout, isPreviewMode }: Props) => {
  const dispatch = useDispatch();
  const { modulesConfigs, layouts, theme } = useSelector(
    (data: RootState) => data.config.config
  );
  const [isEditModeOn, setIsEditModeOn] = React.useState<string | null>(null);

  const footerComponents = useMemo(() => {
    return importComponents(layouts[layout].bottomModules, modulesConfigs);
  }, []);

  const components = useMemo(() => {
    return importComponents(pageComponents, modulesConfigs);
  }, [pageComponents, modulesConfigs]);

  const headerComponents = useMemo(() => {
    return importComponents(layouts[layout].topModules, modulesConfigs);
  }, []);

  const [componentsState, setComponents] = useState<any>();

  useEffect(() => {
    setComponents(components);
  }, [components]);

  const isAllowedToEdit = ['PackageListView', 'FlightList', 'SwitchDatePicker'];
  const isAllowedToEditLayout = ['Footer'];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiStatusMessage, setApiStatusMessage] = useState(
    {} as ApiStatusMessage
  );
  const {
    isUpgradePlanModalOpen,
    agentInfo,
    translations: t,
  } = useSelector((data: RootState) => data.config);
  const { handleNewTab } = useSubscriptionPlanGuard({
    subscriptionObj: agentInfo?.agency?.subscription,
  });

  const handleUpgradeOnClick = async (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    try {
      // // FOR NOW REMOVE OPENING A NEW TAB
      // handleNewTab();
      const res = await upgradeRequestApi({
        plan_id: agentInfo?.agency?.subscription?.plan_id,
        setIsLoading: setIsLoading,
      });
      if (res.status === 'success') {
        setApiStatusMessage({ status: ApiStatus.success, data: res.data });
        return {success: true};
      } else {
        setApiStatusMessage({ status: ApiStatus.error, data: res.data });
      }
    } catch (error: any) {
      setApiStatusMessage({ status: ApiStatus.error, data: error });
    }
    return {success: false};
  };

  const handleModalCancel = () => {
    dispatch(setIsUpgradePlanModalOpen(false));
  };
  useEffect(() => {
    setIsModalOpen(isUpgradePlanModalOpen);
  }, [isUpgradePlanModalOpen]);

  const isLight = agentInfo?.agency?.subscription?.plan_id === PlanIdType.LIGHT;

  const mapComponents = (
    componentsArray: {
      Component: React.LazyExoticComponent<React.ComponentType<any>>;
      ComponentName: any;
    }[]
  ) => {
    return componentsArray?.map((component: any, i: number) => {
      const {
        Component,
        ComponentName,
      }: { Component: any; ComponentName: ModuleNames } = component;

      const instanceName = ComponentName;
      // @ts-ignore: Property 'module' does not exist on type '...'
      const moduleName = modulesConfigs[ComponentName]?.module ?? ComponentName;
      return isAllowedToEdit.includes(moduleName) ? (
        <EditComponent
          instanceName={instanceName}
          moduleName={moduleName}
          key={i}
          id={instanceName}
          index={String(i)}
          setComponents={setComponents}
          isEditModeOn={isEditModeOn}
          setIsEditModeOn={setIsEditModeOn}
        >
          <div className={ComponentName} key={i}>
            {modulesConfigs[ComponentName]?.theme &&
              generateCssVariables(
                `.${ComponentName}`,
                modulesConfigs[ComponentName].theme as any
              )}
            <Component
              config={modulesConfigs[ComponentName]}
              componentStyle={classPriority(
                theme,
                modulesConfigs[ComponentName]
              )}
            />
          </div>
        </EditComponent>
      ) : (
        <div className={ComponentName} key={i}>
          {modulesConfigs[ComponentName]?.theme &&
            generateCssVariables(
              `.${ComponentName}`,
              modulesConfigs[ComponentName].theme as any
            )}
          <Component
            config={modulesConfigs[ComponentName]}
            componentStyle={classPriority(theme, modulesConfigs[ComponentName])}
          />
        </div>
      );
    });
  };

  const mapComponentsLayout = (
    componentsArray: {
      Component: React.LazyExoticComponent<React.ComponentType<any>>;
      ComponentName: any;
    }[],
    name: string
  ) => {
    return componentsArray?.map((component: any, i: number) => {
      const {
        Component,
        ComponentName,
      }: { Component: any; ComponentName: ModuleNames } = component;

      // @ts-ignore: Property 'module' does not exist on type '...'
      const moduleName = modulesConfigs?.[ComponentName]?.module ?? ComponentName;

      return (
        <div
          className={ComponentName}
          style={{ marginBottom: ComponentName === 'Header' ? 0 : 90 }}
          key={i}
        >
          {ComponentName &&
            modulesConfigs[ComponentName]?.theme &&
            generateCssVariables(
              `.${ComponentName}`,
              modulesConfigs[ComponentName].theme as any
            )}
          <EditLayoutComponent
            instanceName={ComponentName}
            key={i}
            id={ComponentName}
            index={String(i)}
            isEditModeOn={isEditModeOn}
            setIsEditModeOn={setIsEditModeOn}
            layout={layout}
            name={name}
            moduleName={moduleName}
            isEditAllowed={isAllowedToEditLayout.includes(moduleName)}
          >
            <Component
              config={modulesConfigs[ComponentName]}
              componentStyle={classPriority(
                theme,
                modulesConfigs[ComponentName]
              )}
            />
          </EditLayoutComponent>
        </div>
      );
    });
  };
  const generateCssVariables = useCssVariables();

  return (
    <div className="app-wrapper">
      <Suspense
        fallback={<Spin className="center lazy-loader-spinner" size="large" />}
      >
        {!isPreviewMode && mapComponentsLayout(headerComponents, 'topModules')}
        {mapComponents(componentsState)}
        {!isPreviewMode &&
          mapComponentsLayout(footerComponents, 'bottomModules')}

        {agentInfo?.agency?.subscription?.plan_id && agentInfo?.agency?.subscription?.plan_id !== PlanIdType.PRO && (
          <UpgradePlanTemplateModal
            open={isModalOpen}
            plan_id={agentInfo?.agency?.subscription?.plan_id}
            upgradePlan_t={
              isLight
                ? t?.upgradePlan?.regular
                : t?.upgradePlan?.pro
            }
            handleUpgradeOnClick={handleUpgradeOnClick}
            onCancel={handleModalCancel}
            apiStatusMessage={apiStatusMessage}
            crud_t={t.crud}
          />
        )}
      </Suspense>
    </div>
  );
};

export default Layout;
