import * as React from "react";

const UpgradePlanBannerSvg = (props) => (
  <svg
    width={294}
    height={83}
    viewBox="0 0 294 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1_667"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={294}
      height={83}
    >
      <rect width={294} height={83} rx={32} fill="#43DCFF" />
    </mask>
    <g mask="url(#mask0_1_667)">
      <g clipPath="url(#clip0_1_667)">
        <path
          d="M226.766 45.0412L227.03 48.8434L242.176 47.7881L219.787 71.7136C218.867 72.6965 218.918 74.2433 219.901 75.1641C220.372 75.6038 220.972 75.821 221.567 75.821C222.219 75.821 222.871 75.5624 223.352 75.0503L245.746 51.1196L245.694 66.3129L249.507 66.3233L249.579 43.4583L226.771 45.0464L226.766 45.0412Z"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeMiterlimit={10}
        />
        <path
          d="M102.012 23.4592L103.879 27.101L119.683 18.9948L104.19 35.7038C98.1632 42.2064 89.6172 45.931 80.7506 45.931H29.8837V52.1387H80.7558C91.3451 52.1387 101.546 47.6847 108.742 39.9251L124.235 23.216L117.34 39.5836L121.116 41.177L135.901 6.06729L102.007 23.454L102.012 23.4592Z"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeMiterlimit={10}
        />
        <path
          d="M197.605 13.7234L194.993 12.0939L191.553 15.5754L194.036 18.4775L197.605 13.7234Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M159.5 67.7924C159.5 67.7924 161.451 87.9622 161.466 88.2571C161.482 88.5468 160.499 96.3892 161.295 96.8392C162.097 97.2893 164.063 97.6773 166.82 98.2256C169.572 98.774 175.128 98.7947 178.982 97.9308C182.836 97.0617 190.528 92.887 191.18 92.2714C191.832 91.6558 193.389 89.6331 193.451 88.8468C193.508 88.0657 191.087 70.2548 191.087 70.2548L191.832 61.4502L196.291 50.9075C196.291 50.9075 210.74 41.4408 212.876 39.2888C215.013 37.1368 220.465 31.0274 220.341 30.448C220.217 29.8686 209.86 21.3123 206.648 19.3207C203.435 17.3342 198.376 13.4855 197.605 13.7182C196.84 13.951 193.037 17.9343 193.379 18.9896C193.72 20.0449 204.511 31.6016 204.511 31.6016L196.928 35.9625L179.334 44.8033C179.334 44.8033 170.416 45.7241 165.315 49.0866C160.214 52.4491 150.556 60.2294 152.284 61.7037C154.012 63.178 159.495 67.7821 159.495 67.7821L159.5 67.7924Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.742 40.9752L203.539 39.7388"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M200.016 41.3735L196.286 50.9075L201.573 42.7702"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M205.474 31.3636C205.474 31.3636 210.362 29.6669 211.842 30.0807C213.321 30.4945 213.838 31.0532 213.838 31.0532"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M213.75 29.3099C213.75 29.3099 211.086 28.6581 209.943 29.2013C208.8 29.7444 208.329 30.06 208.329 30.06"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M161.471 74.3208C161.471 74.3208 166.225 81.6563 170.54 85.5981"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M187.528 50.7833L190.187 55.6098L188.599 90.2694L193.819 95.7063L197.579 88.5675L191.553 55.5426L193.57 51.4403L191.801 47.6226L187.528 50.7833Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M189.949 21.3951C189.949 21.3951 195.293 24.3075 195.329 25.0369C195.365 25.7663 196.069 26.8682 196.069 26.8682C196.069 26.8682 199.25 28.746 199.68 29.2944C200.114 29.8427 199.173 32.243 199.173 32.243C199.173 32.243 201.128 35.7245 201.159 36.2936C201.185 36.8626 200.73 37.5351 200.352 38.0421C199.975 38.549 196.224 40.0285 196.224 40.0285L191.749 49.997C191.749 49.997 190.947 50.1988 190.456 50.1419C189.965 50.085 180.239 40.7838 180.239 40.7838L181.258 38.0731L177.58 30.9394C177.58 30.9394 180.327 23.8989 183.581 22.2797C186.835 20.6605 189.959 21.4003 189.959 21.4003L189.949 21.3951Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M196.219 40.0285L192.199 40.6493L194.843 42.9461L196.219 40.0285Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.055 25.6215C195.055 25.6215 193.229 24.8145 192.267 26.7337"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.07 27.5045C195.288 27.8925 195.283 28.3115 195.06 28.4356C194.838 28.5598 194.481 28.3477 194.263 27.9546C194.046 27.5666 194.051 27.1476 194.274 27.0234C194.496 26.8992 194.853 27.1113 195.07 27.5045Z"
          fill="#263238"
        />
        <path
          d="M198.826 33.7743C198.826 33.7743 198.071 33.2052 196.897 33.8674"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M187.885 22.3883C187.885 22.3883 191.423 21.6486 191.863 20.6502C192.303 19.6518 192.458 16.1496 190.699 16.8066C188.94 17.4636 188.64 17.8826 188.64 17.8826C188.64 17.8826 185.604 13.9666 184.041 15.1822C182.474 16.3979 183.431 17.6498 183.431 17.6498C183.431 17.6498 180.932 18.0947 180.094 19.1914C179.251 20.2881 180.151 20.407 180.151 20.407C180.151 20.407 174.176 24.5145 174 29.3203C173.825 34.126 179.38 38.0007 181.305 39.1232C183.229 40.2458 183.798 40.2199 184.59 39.775C185.381 39.3302 187.052 36.9712 187.052 36.9712C187.052 36.9712 184.372 35.3107 183.912 34.1985C183.452 33.0862 183.953 31.6792 185.004 31.5447C186.054 31.4102 186.99 32.2586 187.088 32.6621C187.187 33.0656 188.211 32.362 188.211 32.362C188.211 32.362 187.068 30.5463 186.711 29.9152C186.354 29.284 188.987 26.5527 188.863 25.6681C188.739 24.7835 185.392 24.4524 185.133 24.142C184.874 23.8316 187.89 22.3832 187.89 22.3832L187.885 22.3883Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M179.593 39.4646L191.744 49.997L187.487 54.2648L178.134 41.8546L179.593 39.4646Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M192.955 47.4622L194.181 48.8796L191.744 49.997L192.955 47.4622Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M194.181 48.8796L195.148 53.5768L191.744 49.997L194.181 48.8796Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M182.903 72.831C182.903 72.831 191.967 72.7068 192.282 72.6913C192.598 72.6758 196.328 75.6607 196.245 76.1883C196.167 76.7212 194.895 78.7852 194.895 78.7852C194.895 78.7852 192.924 81.6201 192.313 82.0753C191.703 82.5253 191.817 82.7323 190.761 82.6754C189.706 82.6236 188.614 81.9356 188.614 81.9356C188.614 81.9356 183.416 81.2372 182.629 80.2233C181.843 79.2094 180.425 78.2213 180.425 78.2213L182.903 72.831Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M192.282 74.8226L194.983 78.4438"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M190.508 76.9126L193.896 80.0474"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M189.54 79.6129L192.479 81.4752"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M196.245 76.1935C196.245 76.1935 196.747 78.8111 196.902 79.3232C197.057 79.8354 196.059 80.5958 195.526 80.2958C194.993 79.9957 194.895 78.7904 194.895 78.7904L196.245 76.1935Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M179.737 70.6635L182.903 72.831L180.42 78.2213L178.129 76.5453L179.737 70.6635Z"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M183.954 55.3874C183.954 55.3874 184.466 48.5072 182.5 45.0205C180.539 41.5287 176.127 41.8494 169.065 44.0894C162.004 46.3293 143.479 59.9862 143.122 61.3726C142.765 62.759 147.044 66.137 149.511 67.0734C151.979 68.0097 174.87 76.9126 175.304 77.2074C175.739 77.5023 177.544 77.7299 178.118 76.5453C178.692 75.3555 180.415 71.6877 179.732 70.6686C179.049 69.6495 168.667 62.0348 168.667 62.0348L178.868 57.11"
          fill="white"
        />
        <path
          d="M183.954 55.3874C183.954 55.3874 184.466 48.5072 182.5 45.0205C180.539 41.5287 176.127 41.8494 169.065 44.0894C162.004 46.3293 143.479 59.9862 143.122 61.3726C142.765 62.759 147.044 66.137 149.511 67.0734C151.979 68.0097 174.87 76.9126 175.304 77.2074C175.739 77.5023 177.544 77.7299 178.118 76.5453C178.692 75.3555 180.415 71.6877 179.732 70.6686C179.049 69.6495 168.667 62.0348 168.667 62.0348L178.868 57.11"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.824 56.2565C154.203 56.4323 153.681 56.6134 153.344 56.7634"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M168.672 62.0296C168.672 62.0296 161.901 57.1928 160.478 56.1013C159.604 55.4339 157.628 55.6098 155.915 55.9823"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M159.014 54.1665C159.014 54.1665 162.17 54.0113 163.241 56.4944"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M177.88 59.7742L179.37 59.619"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M172.195 60.3587L176.158 59.95"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.779 6.25868C195.779 6.25868 197.952 3.86355 198.035 3.56868C198.117 3.27382 198.924 -1.14917 198.924 -1.14917L197.9 -0.228363L195.779 6.25868Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M191.553 15.5754C191.553 15.5754 186.876 11.6128 187.585 10.1178C188.294 8.62276 195.857 1.82534 195.857 1.82534C195.857 1.82534 197.791 -0.507715 197.9 -0.223196C198.014 0.0613235 197.471 3.01515 197.031 3.91009C196.586 4.8102 192.955 10.2471 192.955 10.2471L194.993 12.0991L191.558 15.5806L191.553 15.5754Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M240.33 -74.7414L202.07 -52.1712L207.32 -42.5441C206.606 -41.9441 152.475 20.5622 126.227 50.892C117.355 61.1398 104.454 67.0009 90.9002 66.9389L22.2535 66.6854V87.3622L96.5233 87.3984C113.553 87.4087 129.76 80.0888 141.007 67.3061L217.899 -23.4089L223.734 -12.711L241.545 -72.31L240.33 -74.7414Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M200.854 -54.6026L206.105 -44.9755C205.391 -44.3754 151.26 18.1309 125.012 48.4606C116.14 58.7085 103.238 64.5696 89.6848 64.5075L22.259 64.254V84.9308L95.3132 84.967C112.343 84.9774 128.55 77.6575 139.796 64.8748L216.689 -25.8403L222.524 -15.1423L240.33 -74.7414L200.854 -54.6026Z"
          fill="white"
          stroke="#263238"
          strokeWidth={0.517308}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_667">
        <rect
          width={234.744}
          height={231.49}
          fill="white"
          transform="translate(22 -75)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default UpgradePlanBannerSvg;
