import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {
  CommissionType,
  addCommissionByPerPerson,
  addCommissionByPercent,
  agentCommissionPerPerson,
  agentCommissionPercent,
} from '../../ShoppingCartSliceV2';
import { Input } from 'antd';
import './agentActionProfit.less';
import { debounce } from 'lodash';

interface Props {
  isMangeOrderPage: boolean;
}

const AgentActionProfit = ({ isMangeOrderPage }: Props) => {
  const { shoppingCartTranslations: t, general: general_t } = useSelector(
    (data: RootState) => data.config.translations
  );
  const { agent_commission, ShoppingCartV2 } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );

  const dispatch = useDispatch();

  const [perPersonValue, setPerPersonValue] = useState(
    agent_commission?.per_pax ?? 0
  );
  const [perPercentageValue, setPerPercentageValue] = useState(
    agent_commission?.percentage ?? 0
  );

  const handleCommissionPerPersonChange = debounce((value: number) => {
    // debugger;

    const nonEmptyValue = value ?? 0;
    if (!isNaN(Number(nonEmptyValue))) {
      dispatch(agentCommissionPerPerson(Number(nonEmptyValue)));
      setTimeout(() => {
        dispatch(addCommissionByPerPerson());
      }, 150);
    }
  }, 300); // 300

  const handleCommissionPerPercentageChange = debounce((value: number) => {
    // debugger;

    let nonEmptyValue = value ?? 0;
    if (Number(nonEmptyValue) >= 100) {
      nonEmptyValue = 99;
    }
    if (!isNaN(Number(nonEmptyValue))) {
      dispatch(agentCommissionPercent(Number(nonEmptyValue)));
      setTimeout(() => {
        dispatch(addCommissionByPercent());
      }, 150);
    }
  }, 300); // 300

  const handleChange = (val: number, type: 'perPerson' | 'perPercentage') => {
    if (type === 'perPerson') {
      setPerPersonValue(val);
      handleCommissionPerPersonChange(val);
    } else {
      setPerPercentageValue(val);
      handleCommissionPerPercentageChange(val);
    }
  };

  return (
    <div className="agent-action-profit">
      <div
        className={`input-wrapper ${
          agent_commission?.type !== CommissionType.Percentage
            ? 'input-wrapper-disabled'
            : ''
        }`}
        onClick={(e) => {
          if (agent_commission?.type === CommissionType.PerPax) {
            handleCommissionPerPercentageChange(perPercentageValue);
          }
        }}
      >
        <p>{t.grossProfitPercentage}</p>
        <div>
          <Input
            className="agent--action-input"
            addonAfter={<div>%</div>}
            // onChange={(e) =>
            //   handleCommissionPerPercentageChange(e.target.value)
            // }
            onChange={(e) => handleChange(+e.target.value, 'perPercentage')}
            value={perPercentageValue}
          />
        </div>
      </div>
      <span>{t.or}</span>
      <div
        className={`input-wrapper ${
          agent_commission?.type !== CommissionType.PerPax
            ? 'input-wrapper-disabled'
            : ''
        }`}
        onClick={() => {
          if (agent_commission?.type === CommissionType.Percentage)
            handleCommissionPerPersonChange(perPersonValue);
        }}
      >
        <p>{t.profitPerPerson}</p>
        <Input
          className="agent--action-input"
          addonAfter={<div>{ShoppingCartV2.currency_sign}</div>}
          // onChange={(e) => handleCommissionPerPersonChange(e.target.value)}
          onChange={(e) => handleChange(+e.target.value, 'perPerson')}
          value={perPersonValue}
        />
      </div>
    </div>
  );
};

export default AgentActionProfit;
