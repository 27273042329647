import { Modal } from 'antd';
import './inputWithMovingPlaceholder.less';
import React, { useRef, useState } from 'react';
import { Input } from 'antd';
import moment from 'moment';

interface InputWithMovingPlaceholderProps
  extends React.ComponentProps<typeof Input> {
  placeholder?: string;
  onChange: any;
  value: any;
  type?: string | undefined;
  disabled?: boolean;
  required?: boolean;
}

const InputWithMovingPlaceholder = ({
  disabled,
  placeholder,
  value = '',
  onChange,
  type = 'text',
  required,
}: InputWithMovingPlaceholderProps) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (value === '') {
      setFocused(false);
    }
  };

  const handleChange = (e: any) => {
    if (type === 'date') {
      return onChange(e);
    }
    onChange(e);
  };

  const handleClickPlaceholder = () => {
    setFocused(true);
    ref.current.focus();
  };

  const ref = useRef<any>(null);
  return (
    <div
      className={`input-with-moving-placeholder ${
        focused || value !== '' ? 'ant-input-focused' : ''
      }`}
    >
      {placeholder && (
        <span className="placeholder" onClick={handleClickPlaceholder}>
          {placeholder}
        </span>
      )}
      <Input
        required={required}
        disabled={disabled}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={ref}
        type={type}
      />
    </div>
  );
};

export default InputWithMovingPlaceholder;
