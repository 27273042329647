const translationsEn = {
    "status": "success",
    "data": {
        "lang": "fr_FR",
        "app": "hotel",
        "translation": {
            "crud": {
                "validationFailed": "Échec de la validation",
                "operationError": "Une erreur est survenue",
                "emptyList": "Aucune donnée trouvée",
                "failToLoad": "Erreur lors du chargement",
                "modelNotFound": "Non trouvé"
            },
            "upgradePlan": {
                "general": {
                    "upgrade_plan": "Plan de mise à niveau",
                    "upgrade_plan_to_regular": "Mise à niveau vers le plan régulier",
                    "upgrade_plan_to_pro": "Mise à niveau vers le plan PRO",
                  },
                "regular": {
                    "title": "Mettez à niveau maintenant et accédez aux produits Agent 360 !",
                    "benefits_title_text": "Vous êtes actuellement connecté au forfait Agent 360 Light, si vous effectuez une mise à niveau, vous pourrez bénéficier de",
                    "text_1": "Produits en gros Sky 360",
                    "text_2": "Hôtels pour toutes les destinations dans le monde",
                    "text_3": "Vols réguliers et low-cost",
                    "text_4": "Attractions dans le monde entier",
                    "button_text": "Mettez à niveau maintenant vers Agent 360 !"
                },
                "pro": {
                    "title": "Mettez à niveau maintenant et obtenez un site web pour votre entreprise avec un branding complet !",
                    "benefits_title_text": "Dans le forfait SKY 360 Pro, vous pouvez profiter de",
                    "text_1": "Un site web avec votre domaine unique",
                    "text_2": "Branding d'entreprise - logo et couleurs du site",
                    "text_3": "Promotion de forfaits et de vols sur le site par destinations",
                    "text_4": "Toutes les réservations effectuées appartiendront à votre agence !",
                    "button_text": "Mettez à niveau maintenant vers Pro !"
                }
            },
            "attractionQuestions": {
                "customerInfo": "Customer Info",
                "participantsInfo": "Participants Info",

                "first_name": "First Name",
                "last_name": "Last Name",
                "email_address": "Email Address",
                "phone_number": "Phone Number",
                "pickup_location": "Pickup Location",
                "height_in_centimeters": "Height in Centimeters",
                "weight_in_kilograms": "Weight in Kilograms",
            },
            "contactUs": {
                "requestForQuotation": "Demande de devis",
                "problemWithExistingOrder": "Problème avec une commande existante",
                "customerService": "Service client",
                "other": "Autre",
                "messageReceivedSuccessfully": "Message reçu avec succès",
                "email": "Email",
                "phone": "Téléphone",
                "fullName": "Nom complet",
                "writeUsMessage": "Écrivez-nous un message",
                "send": "Envoyer",
                "phoneRequired": "Veuillez entrer un numéro de téléphone",
                "emailRequired": "Veuillez entrer une adresse e-mail",
                "fullNameRequired": "Veuillez entrer un nom complet",
                "messageRequired": "Veuillez entrer un message",
                "aboutUs": "À propos de nous"
            },
            "cart": {
                "addToCart": "Ajouter au panier"
            },
            "customerSupport": {
                "customerService": "Service client",
                "sendMessageAndGetNotified": "Envoyez-nous un message et l'enchérisseur vous répondra dès que possible",
                "callUs": "Appelez-nous :"
            },
            "roomInfo": {
                "info": "Description de la chambre",
                "viewAllThePhotos": "Voir toutes les photos",
                "roomSize": "Taille de la chambre (m²)",
                "peopleInTheRoom": "Personnes dans la chambre",
                "doubleBed": "Lit double"
            },
            "conceptsList": {
                "conceptsListTitle": "Titre de la liste des concepts",
                "includesActivities": "Attractions offertes",
                "includesActivity": "Comprend une attraction offerte",
                "includeInPackage": "Inclus dans le forfait",
                "transfer": "Transfert",
                "includesTransfer": "Comprend le transfert",
                "transferContent": "Transferts HOS au prix du forfait",
                "baseBoard": "Formule de base",
                "autoApprove": "Approbation automatique",
                "airways": "Compagnies aériennes",
                "flightFrom": "Vol depuis",
                "flightBack": "Vol retour",
                "nights": "Nuits",
                "toOrder": "Commander",
                "noPackagesFound": "Aucun forfait trouvé",
                "moreDeals": "Plus d'offres",
                "pricePerPerson": "Prix par personne"
            },
            "viewFlight": {
                "show_tickets_with_different_fare_combinations": "Afficher les billets avec différentes combinaisons de tarifs",
                "ticketFare": "Tarif",
                "ticketInfo": "Informations sur le billet",
                "termsAndConditions": "Conditions générales",
                "ifTicketIncludeSeat": "Si le billet comprend un siège - le client devra le choisir sur le site de la compagnie aérienne",
                "inCaseOfChange": "En cas de modification - le client devra payer un supplément si le billet mis à jour est plus cher, des frais de service supplémentaires s'appliquent selon les Conditions générales du site",
                "inCaseOfCancellation": "En cas d'annulation - des frais de service supplémentaires s'appliquent selon les Conditions générales du site",
                "error4305Title": "Oups, le vol est complet",
                "error4305Content": "Nous nous excusons, mais il semble que les places pour cette offre soient épuisées, nous ne pouvons pas procéder à l'achat. Vous êtes redirigé vers la page principale.",
                "TC": "CG",
                "flights": "Vols",
                "title": "Vol sélectionné, vérifiez les détails et procédez au paiement",
                "addBaggage": "Ajouter des bagages",
                "closeAddBaggage": "Masquer les options",
                "addTrolly": "Ajouter un chariot",
                "somethingWetWrong": "Quelque chose s'est mal passé",
                "suitcasePrice": "Prix de la valise",
                "trollyPrice": "Prix du chariot",
                "totalPerPassenger": "Total par passager",
                "updatePassengers": "Mettre à jour le nombre de passagers",
                "search": "Rechercher",
                "seating": "Places",
                "cancelation": "Annulation du billet",
                "meal": "Repas",
                "trolly": "Chariot",
                "suitcase": "Valise",
                "changeFlight": "Changer le billet",
                "extension": "Prolongation",
                "totalToBePaid": "Total à payer",
                "choose": "Choisir",
                "checkAmenities": "Vérifier les équipements",
                "description": "Description",
                "amenityType": "Type d'équipement",
                "flightFromAmenities": "Équipements du vol aller",
                "flightBackAmenities": "Équipements du vol retour",
                "BAGGAGE": "Bagages",
                "2 CHECKED BAGS UP TO 32KG EACH": "2 bagages enregistrés jusqu'à 32 kg chacun",
                "2 CABIN BAGS UP TO 8KG": "2 bagages cabine jusqu'à 8 kg",
                "BRANDED_FARES": "Tarifs de marque",
                "STANDARD SEAT RESERVATION": "Réservation de siège standard",
                "MILEAGE_ACCRUAL": "Accumulation de miles",
                "MEAL": "Repas",
                "SNACK": "Collation",
                "COMPLIMENTARY FOOD AND BEV": "Nourriture et boissons offertes",
                "ENTERTAINMENT": "Divertissement",
                "MAGAZINES / NEWSPAPER": "Magazines / Journaux",
                "LOUNGE": "Salon",
                "LOUNGE ACCESS": "Accès au salon",
                "TRAVEL_SERVICES": "Services de voyage",
                "PRIORITY BAGGAGE": "Bagages prioritaires",
                "PRIORITY CHECK IN": "Enregistrement prioritaire",
                "PRIORITY SECURITY": "Sécurité prioritaire",
                "PRIORITY BOARDING": "Embarquement prioritaire",
                "IN FLIGHT ENTERTAINMENT": "Divertissement à bord",
                "PRE_RESERVED_SEAT": "Siège pré-réservé",
                "PRIVACY SEAT BUSINESS CLASS": "Siège privé en classe affaires",
                "CHANGE BEFORE DEPARTURE": "Changement avant le départ",
                "CHANGE AFTER DEPARTURE": "Changement après le départ",
                "REFUND BEFORE DEPARTURE": "Remboursement avant le départ",
                "REFUND AFTER DEPARTURE": "Remboursement après le départ",
                "allFares": "Tous les tarifs",
                "fareChangedSuccessfully": "Tarif modifié avec succès",
                "openFares": "Tarifs ouverts",
                "selectedFare": "Tarif sélectionné",
                "fullInfo": "Informations complètes",
                "MILEAGE ACCRUAL": "Accumulation de miles"
            },
            "room": {
                "roomSize": "Taille de la chambre",
                "bedType": "Type de lit",
                "breakfastIncluded": "Petit-déjeuner inclus",
                "noAvailabilityOnSelectedDates": "Aucune disponibilité pour cette chambre aux dates sélectionnées"
            },
            "promotion": {
                "modelNotFound": "Page non trouvée",
                "failToLoad": "Échec du chargement de la page !"
            },
            "searchDatePicker": {
                "freePlanFlightSearchFooterText": "Le plan gratuit permet de rechercher uniquement des vols charter ! Si vous souhaitez des vols GDS",
                "clickHere": "Cliquez ici",
                "approveSelection": "Approuver la sélection",
                "room": "Chambre",
                "addRoom": "Ajouter une chambre",
                "removeRoom": "Supprimer la chambre",
                "indirectFlight": "Vol avec escale",
                "directFlightLabel": "Vol direct",
                "city": "Ville",
                "hotel": "Hôtel",
                "searchForDestination": "Rechercher des destinations",
                "searchByDestinationAndHotel": "Rechercher par destinations et hôtels",
                "date": "Date",
                "noResultsForThisDate": "Aucun forfait trouvé pour cette date",
                "searchDestination": "Rechercher une destination",
                "pleaseSelectDestination": "Veuillez sélectionner une destination",
                "airlineInformation": "Informations sur la compagnie aérienne",
                "airlineCode": "Code",
                "title": "Réservez vos vacances de rêve",
                "hotelOnly": "Rechercher des vacances par dates",
                "hotelWithFlight": "Rechercher des vacances incluant un vol",
                "oneWay": "Aller simple",
                "roundTrip": "Aller-retour",
                "selectDate": "Sélection des dates",
                "startDate": "Date de début",
                "endDate": "Date de fin",
                "flights": "Rechercher des vols",
                "packages": "Forfaits vacances",
                "hotels": "Hôtels à l'étranger",
                "israelPackage": "Vacances en Israël",
                "specialPackage": "Forfaits spéciaux",
                "passengers": "Passagers",
                "adults": "Adultes",
                "adult": "Adulte",
                "child": "Enfant",
                "children": "Enfants",
                "infant": "Bébé",
                "infants": "Bébés",
                "infantsWarningMessage": "Le bébé doit avoir moins de deux ans à l'aller et au retour",
                "select": "Sélectionner",
                "noResults": "Aucun résultat",
                "checkout": "Réservez maintenant",
                "regularPrice": "Prix régulier",
                "from": "À partir de",
                "purchase": "Acheter",
                "selectNewFlight": "Sélectionnez un nouveau vol",
                "search": "Rechercher",
                "rooms": "Chambres",
                "composition": "Composition",
                "chooseComposition": "Choisissez la composition",
                "numberOfGuests": "Nombre de clients",
                "checkOut": "Départ",
                "checkIn": "Arrivée",
                "whatVacation": "Quel type de vacances",
                "youWant": "voulez-vous",
                "vacationFriendsWith": "Vacances avec des amis",
                "familyVacation": "Vacances en famille",
                "romanticVacation": "Vacances romantiques",
                "notAvailable": "Non disponible",
                "roomAvailableDuringTheWeek": "Disponibilité de la chambre pendant la semaine",
                "followingDates": "Dates suivantes",
                "vacation": "Vacances",
                "abroad": "À l'étranger",
                "inIsrael": "Dans le pays",
                "destination": "Toutes les destinations",
                "chooseDestination": "Choisissez une destination",
                "findDreamVacation": "Trouvez vos vacances parfaites en un clic.",
                "searchResTitle": "Choisissez le forfait qui vous convient",
                "directFlight": "Vol direct",
                "stops": "escales",
                "checkHotel": "Vérifiez l'hôtel",
                "noRating": "Pas de note",
                "toOrder": "Commander",
                "selectAgain": "Sélectionnez à nouveau",
                "selectFlight": "Sélectionnez un vol",
                "reselectFlight": "Resélectionner les vols",
                "flightSelectedSuccessfully": "Vol sélectionné avec succès",
                "dynamicFlight": "Vol dynamique",
                "bundle": "Vol groupé",
                "freeCancelation": "Annulation gratuite",
                "partially_operated_by_airlines": "Partiellement opéré par des compagnies aériennes",
                "connect_in_airport": "Correspondance à l'aéroport",
                "arrives": "Arrive",
                "journey_duration": "Durée du trajet",
                "short_wait": "Attente courte",
                "long_wait": "Attente longue",
                "flight_number": "Numéro de vol",
                "stop_1": "Une escale",
                "activeFlight": "Vol activé",
                "flightSearch": "Recherche de vol",
                "nights": "Nuits",
                "until": "Jusqu'à la date",
                "untilYouSearchForFlightDate": "Jusqu'à la date de recherche du vol",
                "destinationFrom": "Départ",
                "packageSearch": "Recherche de forfait",
                "destinationTo": "Arrivée",
                "selectDestination": "Destination",
                "trollyIsIncluded": "Chariot inclus",
                "trollyIsNotIncluded": "Chariot non inclus",
                "suitcaseIsIncluded": "Valise incluse",
                "suitcaseIsNotIncluded": "Valise non incluse",
                "operated_by_airlines": "Opéré par"
            },
            "specialDeal": {
                "closeTheDeal": "Conclure l'affaire ›",
                "endsIn": "Se termine dans",
                "specialDealForTheWeekend": "Offre spéciale pour le week-end",
                "from": "À partir de la date",
                "until": "Jusqu'à la date"
            },
            "footer": {
                "backToTop": "Retour en haut",
                "allRightsReserved": "© Tous droits réservés. Ne pas copier le contenu du site sans permission.",
                "developBy": "Développé par SKY 360 Tech.",
                "thePurchaseOnTheWebsiteIsSecure": "L'achat sur le site est sécurisé.",
                "talkToUs": "Comment nous contacter ?",
                "address": "Adresse",
                "phoneAndWhatsapp": "Téléphone ou WhatsApp",
                "phone": "Téléphone",
                "whatsapp": "Whatsapp"
            },
            "packages": {
                "availableSeats": "Places disponibles",
                "from": "À partir de la date",
                "until": "Jusqu'à la date",
                "breakfastIncluded": "Petit-déjeuner inclus",
                "includesMassageAndSpa": "Inclut massage et spa !",
                "lastRoom": "Dernière chambre !",
                "includesTransfers": "Inclut les transferts",
                "includesActivities": "Inclut des attractions",
                "nights": "Nuits",
                "withoutBaggage": "Sans bagage",
                "directFlight": "Vol direct",
                "oneStop": "Une escale",
                "numOfStops": "Escales",
                "flightDetails": "Détails du vol",
                "flightFrom": " de -> à",
                "flightBack": " de -> à",
                "hours": "Heures",
                "flightNumber": "Numéro de vol",
                "airLine": "Compagnie aérienne",
                "suitcaseWeight": "poidskg Bagage inclus",
                "trollyWeight": "poidskg Chariot inclus",
                "includedActivitiesTitle": "Attractions et visites dans le forfait",
                "checkAllPictures": "Voir toutes les photos",
                "affiliateWith": "En collaboration avec",
                "addButtonDisabledTooltip": "La commande est limitée à la quantité de chambres - impossible d'ajouter une autre chambre. Pour changer de chambre, retirez-en une du panier",
                "holidaySchedule": "Programme des vacances",
                "passengers": "Passagers",
                "isRoundTrip": "Prix des bagages pour un aller-retour *",
                "includesFlights": "Inclut les vols",
                "returnFlight": "Vol retour",
                "outboundFlight": "Vol aller",
                "trollyWeight2": "poids kg chariot / sac",
                "suitcaseWeight2": "poids kg valise",
                "included": "Inclus",
                "notIncluded": "Non inclus",
                "perPax": "Par passager",
                "addSuitcaseLater": "Vous pouvez ajouter plus tard",
                "clickForDetails": "Cliquez ici pour les détails de l'attraction",
                "includedInPackage": "Inclus dans le forfait !"
            },
            "roomPricing": {
                "for": "pour",
                "addPassenger": "Ajouter un passager",
                "pricePerPerson": "Prix par personne",
                "pricePerRoom": "Prix par chambre",
                "priceForAllRooms": "Prix pour toutes les chambres",
                "payOnSpot": "Payer sur place",
                "resort_fee": "Frais de séjour",
                "occupancy_tax": "Taxe d'occupation",
                "totalPrice": "Prix total",
                "pricePerInfant": "Prix par bébé",
                "composition": "Composition",
                "adultsInTheRoom": "Adultes",
                "children": "Enfants",
                "adults": "Adultes",
                "single": "Adulte seul",
                "child": "Enfant",
                "selectInfantCount": "Sélectionnez dans la liste",
                "infant": "Bébé",
                "1infant": "Un bébé",
                "2infants": "2 bébés",
                "infants": "Bébés",
                "noInfants": "Sans bébés",
                "all_include": "Tout inclus",
                "breakfast": "Petit-déjeuner",
                "activities": "Choisir une visite",
                "half_board": "Demi-pension",
                "half_board_kosher": "Demi-pension casher",
                "full_board": "Pension complète",
                "sleep_only": "Chambre uniquement",
                "breakfast_kidush": "Repas du matin et du soir du vendredi",
                "addToCard": "Ajouter à la commande",
                "inPackageDates": "Dates du forfait",
                "chooseRoom": "Choisir la composition de la chambre",
                "nights": "Nuits",
                "boardType": "Type de pension",
                "filters": "Filtres",
                "noAvailableRooms": "Aucune chambre disponible pour les dates sélectionnées",
                "noAvailableRoomsForDates": "Chambre non disponible pour les dates sélectionnées",
                "addInfant": "Ajouter un bébé",
                "roomFacilities": "Équipements de la chambre",
                "show_images": "Voir les images",
                "autoApprove": "Approbation automatique",
                "includedInPrice": "Inclus dans le prix",
                "until": "Jusqu'à",
                "no": "Non",
                "vat": "TVA",
                "is": "Est",
                "not": "Non",
                "city_tax": "Taxe de séjour",
                "cancellation_policy": "Politique d'annulation",
                "your_local_time": "*Votre heure locale",
                "additional_price_info": "Informations de prix supplémentaires",
                "policy": {
                    "freeCancallationUntil": "Annulation gratuite à ce tarif jusqu'à",
                    "freeCancallationUntil2": "Annulation gratuite jusqu'à",
                    "cancallationBeCharged": "Vous serez facturé",
                    "ifYouCancelBetween": "Si vous annulez à partir de",
                    "ifYouCancelModifiedBetween": "Si vous annulez ou modifiez après",
                    "cancelFullPrice": "le prix total de la réservation sera facturé",
                    "till": "jusqu'à",
                    "rateNonRefundable": "Le tarif est non remboursable en cas d'annulation"
                }
            },
            "boards": {
                "RO": "CHAMBRE SEULEMENT",
                "BB": "PETIT-DÉJEUNER INCLUS",
                "HB": "DEMI-PENSION",
                "FB": "PENSION COMPLÈTE",
                "AI": "TOUT INCLUS",
                "UI": "ULTRA TOUT INCLUS DE LUXE",
                "BF": "PETIT-DÉJEUNER BUFFET",
                "KB": "PETIT-DÉJEUNER FROID BUFFET",
                "CB": "PETIT-DÉJEUNER CONTINENTAL",
                "AB": "PETIT-DÉJEUNER AMÉRICAIN",
                "DR": "DÎNER ET CHAMBRE",
                "EB": "PETIT-DÉJEUNER ANGLAIS",
                "FT": "PENSION COMPLÈTE + TRAITEMENTS",
                "F+": "PENSION COMPLÈTE PLUS",
                "GA": "DÎNER GALA",
                "H6": "DEMI-PENSION + 6 TRAITEMENTS UNIQUEMENT",
                "HT": "DEMI-PENSION + TRAITEMENTS",
                "IB": "PETIT-DÉJEUNER ISRAÉLIEN",
                "KO": "CASCHER",
                "KS": "PETIT-DÉJEUNER CASCHER",
                "MI": "MAX TOUT INCLUS",
                "RB": "CHAMBRE & PETIT-DÉJEUNER",
                "NN": "VOIR ITINÉRAIRE",
                "SI": "SOFT TOUT INCLUS",
                "UL": "ULTRA TOUT INCLUS",
                "half_board": "Demi-pension",
                "half_board_kosher": "Demi-pension casher",
                "full_board": "Pension complète",
                "sleep_only": "Chambre uniquement",
                "breakfast_kidush": "Repas du matin et du soir du vendredi",
                "breakfast": "BB",
                "breakfast-for-2": "BB pour 2",
                "nomeal": "Chambre uniquement"
            },
            "activitiesTranslations": {
                "addToOrder": "Ajouter à la commande",
                "passenger": "Pour le passager",
                "showMore": "Afficher plus",
                "addAttractionsAndTrips": "Ajouter des attractions et des excursions",
                "doYouWantToAddAttractions": "Voulez-vous ajouter des attractions à vos vacances ?",
                "itsTime": "Il est temps !",
                "pricePerPerson": "Prix par personne"
            },
            "modal": {
                "viewAllImages": "Voir toutes les images",
                "galleryTitle": "Galerie de photos",
                "cancelationTerms": "Conditions d'annulation - ",
                "nameOfTheHotel": "Détails de l'hôtel",
                "hotelInfo": "À propos de l'hôtel",
                "hotelWebsite": "Lien vers le site de l'hôtel",
                "showMore": "Afficher plus",
                "showLess": "Afficher moins"
            },
            "manageBaggage": {
                "manageBaggage": "Gérer les bagages",
                "and": "Et",
                "save": "Sauvegarder",
                "purchaseIsNotSupported": "l'achat n'est pas pris en charge",
                "baggageIsForBothFlights": "Vous pouvez ajouter des bagages pour un vol aller-retour",
                "suitcase": "Valise",
                "trolly": "Chariot",
                "guest": "Invité"
            },
            "shoppingCartTranslations": {
                'attractionPriceDescription': "Le prix de l'attraction dépend de la date, du choix des options et du nombre de billets",
                "selectedDate": "Date sélectionnée",
                "selectADate": "Sélectionnez une date",
                "availableDatesBetween": "Dates disponibles entre",
                "Options": "Options",
                "Text": "Texte",
                "Ticket type": "Type de billet",
                "Start Time": "Heure de début",
                "Pick Up Location": "Lieu de prise en charge",
                "Custom Pick Up Location": "Lieu de prise en charge personnalisé",
                "Drop Off Location": "Lieu de dépose",
                "Custom Drop Off Location": "Lieu de dépose personnalisé",

                "manageBaggage": "Gérer les bagages",
                "youCanAddGuestsOnlyUpToTheAvailableSeats": "Vous pouvez ajouter des invités uniquement jusqu'au nombre de places disponibles",
                "shoppingCartButtonTooltip": "Choisissez une composition et ajoutez à la commande",
                "totalPrice": "Total à payer",
                "proceedToPayment": "Procéder au paiement",
                "placeOrder": "Procéder à l'enregistrement",
                "NoHotelWasChosenForTheVacation": "Aucun hôtel n'a été choisi pour les vacances",
                "betweenDates": "Entre les dates",
                "packageInformation": "Détails du forfait",
                "rooms": "Détails de la chambre",
                "transfers": "Transferts",
                "reg_transfers": "Transferts/navettes vers et depuis l'hôtel",
                "shuttle_transfers": "Navettes de la frontière à l'hôtel et retour",
                "flights": "Vols",
                "activities": "Attractions",
                "shoppingCart": "Panier",
                "package": "Nuits à l'hôtel",
                "nights": "Nuits",
                "inHotel": "À l'hôtel",
                "apply": "Confirmer",
                "promoCode": "Code promo",
                "yourShoppingCart": "Votre panier",
                "isEmpty": "Encore vide...",
                "myVacation": "Mes vacances",
                "showAll": "Afficher tout »",
                "beforeDiscount": "Total avant remise -",
                "agentCommission": "Commission de l'agent -",
                "agentCommissionNet": "Commission de l'agent (paiement net) -",
                "discountFromCode": "Remise avec code -",
                "affiliateWith": "En collaboration avec -",
                "tpId": "ID de commande -",
                "chargeTypeFullCC": "Paiement intégral - y compris la commission",
                "chargeTypeNetCC": "Paiement par abonnement pour le prix net",
                "chargeTypeCash": "Transaction en espèces",
                "agentCommissionPrec": "Pourcentage de commission de l'agent",
                "priceOff": "Remise",
                "percentageOff": "Pourcentage de remise",
                "selectedItems": "Articles sélectionnés",
                "viewProduct": "Voir un produit",
                "infantAdditionalPrice": "Prix supplémentaire pour bébé",
                "netPriceWarning": "Prix net, veuillez ajouter du profit !",
                "copyOrderLink": "Copier le lien",
                "createOrder": "Enregistrer l'offre",
                "updateCommission": "Enregistrer",
                "commissionNotSaved": "Veuillez cliquer pour enregistrer !",
                "clearCart": "Cliquez pour vider le panier",
                "assignCustomer": "Attribuer un client",
                "firstName": "Prénom",
                "lastName": "Nom",
                "phone": "Téléphone",
                "comment": "Commentaire",
                "email": "Email",
                "wrongEmailFormat": "Format d'e-mail incorrect",
                "saveCustomer": "Enregistrer le client",
                "updateCustomerTitle": "Mettre à jour le client",
                "createCustomerTitle": "Créer un nouveau client",
                "sendByWhatsApp": "Envoyer par WhatsApp",
                "copyText": "Copier le texte",
                "sendBySMS": "Envoyer par SMS",
                "findQuoteFor": "Envoyer une offre via",
                "linkIsGive": "L'offre est en direct",
                "dates": "Dates",
                "hotelName": "Nom de l'hôtel",
                "flightPrice": "Prix du vol",
                "suitcase": "Valise",
                "trolly": "Chariot",
                "passengers": "Passagers",
                "totalAgentComm": "Commission totale de l'agent",
                "agentOptions": "Options de l'agent",
                "compareDisableForAgent": "La comparaison de prix est disponible lorsque deux devis sont dans le panier",
                "createNewUser": "Créer une nouvelle offre",
                "comparePrice": "Comparer les prix",
                "send": "Envoyer l'offre",
                "profitPercentageChange": "Modifier le profit",
                "or": "Ou",
                "approval": "Approbation",
                "profitPerPerson": "Profit par personne",
                "grossProfitPercentage": "Pourcentage de profit",
                "actionNotAllowed": "Action non autorisée",
                "orderIsAlreadySaved": "La commande est déjà enregistrée et aucun produit supplémentaire ne peut être ajouté",
                "clickToClear": "Cliquez pour vider le panier",
                "roomsByRequestSubjectOfHotelAvailability": "Impossible d'ajouter le même type de chambre, veuillez en sélectionner un autre",
                "roomsByRequestSubjectOfHotelAvailabilityTitle": "Échec de l'ajout de la chambre",

                "attractionAlreadyAdded": "Impossible d'ajouter le même type d'attraction, veuillez sélectionner un type d'attraction différent",
                "failedToAddAttraction": "Échec de l'ajout de l'attraction",
                "noAvailabilityOnThisDates": "Aucune disponibilité pour les dates sélectionnées",
                "attractionSearchFailed": "Échec de la recherche d'attraction",
                "cannotSearchForThePastDates": "Impossible de rechercher des dates passées",
                "searchOtherForDates": "Aucune disponibilité, veuillez rechercher d'autres dates disponibles",
                "adult": "Adulte",
                "child": "Enfant",
                "infant": "Bébé",
                "perPerson": "Par personne",
                "perVehicle": "Par véhicule",
                "group": "Groupe",
                "family": "Famille",
                "perEquipment": "Par équipement",
                "perBoat": "Par bateau",
                "none": "Aucun"
            },
            "checkout": {
                "addBaggage": "Ajouter des bagages",
                "newGuest": "Nouvel invité",
                "chooseExistingGuest": "Utiliser un invité existant",
                "additionalBaggage": "Bagages supplémentaires",
                "free": "gratuit",
                "baggageIsForBothFlights": "Vous pouvez ajouter des bagages pour un vol aller-retour",
                "additionalBaggageForThisGuest": "Bagages supplémentaires pour cet invité",
                "orderFailedToLoad": "Une erreur s'est produite lors du chargement de l'offre",
                "cartNoLongerValid": "Le panier n'est plus valide",
                "room": "Chambre",
                "personalInfo": "Détails de la commande",
                "paymentDetails": "Paiement",
                "orderCompletion": "Terminer la commande",
                "userDetails": "Informations du client",
                "name": "Prénom",
                "lastName": "Nom de famille",
                "passport": "Passeport",
                "inEnlgish": "",
                "birthdate": "Date de naissance",
                "phone": "Téléphone",
                "email": "Email",
                "notesForTheOrder": "Remarques pour la commande",
                "namesOfGuests": "Passagers",
                "namesMustMatchPassport": "* Tous les noms doivent être saisis en anglais exactement comme sur le passeport",
                "goToPayments": "Soumettre et procéder au paiement",
                "saveOrder": "Enregistrer la commande",
                "agreement": "Je confirme les conditions du site, les conditions de commande et les conditions d'annulation de la commande",
                "men": "Homme",
                "women": "Femme",
                "pleaseInputYourPhone": "Veuillez entrer votre numéro de téléphone",
                "pleaseInputYourLastName": "Veuillez entrer votre nom de famille",
                "pleaseInputYourFirstName": "Veuillez entrer votre prénom",
                "pleaseInputYourEmail": "Veuillez entrer votre email",
                "pleaseInputYourPassportNumber": "Veuillez entrer votre numéro de passeport",
                "pleaseInputYourDateOfBirth": "Veuillez entrer votre date de naissance",
                "pleaseSelectGender": "Veuillez sélectionner le sexe",
                "maxChildAge": "Enfant entre les âges de 2-",
                "maxInfantAge": "Les bébés doivent avoir moins de deux ans au moment des vacances",
                "pleaseAgreeWithT&C": "Veuillez accepter les conditions d'utilisation et le règlement du site",
                "viewTerms": "Voir le règlement",
                "invalidEmail": "Email invalide",
                "orderCreateSuccess": "Votre commande a été enregistrée avec succès, vous êtes redirigé vers le paiement",
                "orderCreateFree": "Votre commande a été enregistrée avec succès !",
                "orderUpdateSuccess": "Votre commande a été mise à jour avec succès !",
                "mainRoomGuestTitle": "Chambre au nom de",
                "uploadPassportImage": "Télécharger la photo du passeport",
                "addInfant": "Ajouter un bébé",
                "updateInfant": "Mettre à jour la quantité de bébés",
                "howManyInfantsDoYouWantToAdd": "Choisissez le nombre de bébés pour cette chambre",
                "add": "Ajouter",
                "setInfantCountForRoom": "Mettre à jour la quantité de bébés",
                "setInfantCountForRoomSuccess": "Le nombre de bébés a été mis à jour avec succès",
                "infantsAddedToTheRoom": "Bébés ajoutés à la chambre",
                "selectNumberOfInfants": "Choisissez le nombre de bébés",
                "pricePerInfant": "Prix par bébé",
                "infantsRemoved": "Les bébés ont été retirés de cette chambre",
                "requiredUploadPassportImage": "Le téléchargement de la photo du passeport est obligatoire",
                "infantError4403": "Impossible de changer le nombre de bébés - veuillez contacter le centre de service",
                "docketId": "* ID du dossier",
                "agencyId": "* ID de l'agence"
            },
            "manageOrder": {
                "moveToPayment": "Ajouter un paiement",
                "backToManage": "Retourner à la commande",
                "paymentTitle": "Paiement de la commande",
                "manageTitle": "Mettre à jour les informations de la commande",
                "modelNotFound": "Commande non trouvée",
                "orderId": "Gestion de la commande"
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "La commande a été effectuée avec succès - paiement complété",
                    "info": "Un email avec les détails de la commande a été envoyé à votre adresse"
                },
                "waitingToApprove": {
                    "title": "Votre commande a été envoyée pour approbation",
                    "info1": "Les détails de la commande ont été enregistrés dans le système et envoyés pour approbation.",
                    "info2": "Le paiement sera effectué uniquement après l'approbation de la commande.",
                    "info3": "Un email de confirmation de commande et de paiement sera envoyé après l'approbation de la commande."
                },
                "paymentFail": {
                    "title": "Échec du paiement",
                    "info1": "Le paiement a échoué :(",
                    "infoFail": "Après plusieurs tentatives, la facturation a échoué - veuillez réessayer",
                    "infoWhatsapp": "Si une erreur s'est produite et que vous ne pouvez pas payer, veuillez contacter via WhatsApp au :"
                },
                "orderNum": "Numéro de commande",
                "thanksForChoose": "Merci d'avoir choisi",
                "free": {
                    "title": "L'inscription a réussi",
                    "info": "Un email avec un résumé de la commande vous a été envoyé"
                },
                "pay_later": {
                    "title": "L'inscription a réussi",
                    "info": "Un email de confirmation de commande vous a été envoyé"
                }
            },
            "paymentForm": {
                "priceCanNotBeOverBalance": "Le prix ne peut pas dépasser le solde",
                "total": "Lien total",
                "balance": "Solde total",
                "updatedBalance": "solde mis à jour",
                "copyLink": "Copier le lien",
                "linkFor": "Lien pour",
                "back": "Retour",
                "setSpecificPrice": "Lien de paiement",
                "createLink": "Créer un lien",
                "setPriceAndSendPaymentLinkToClient": "Définir le prix et envoyer le paiement au client",
                "clientName": "Nom du client",
                "paymentPageFor": "Page de paiement pour",
                "forPayment": "Pour paiement",
                "price": "Prix",
                "copyPaymentLink": "Copier le lien de paiement",
                "pleaseFillInBothFields": "Veuillez remplir les deux champs.",
                "paymentLinkCopiedToClipboard": "Lien de paiement copié dans le presse-papiers !",
                "createLinkForPrice": "Créer un lien pour le paiement",
                "creditNotSaveInBd": "Les détails de la carte de crédit ne sont pas enregistrés sur le site - vous effectuez un paiement sécurisé",
                "title": "Entrez la méthode de paiement (Visa & Mastercard UNIQUEMENT)",
                "splitBtn": "Cliquez pour diviser les cartes de crédit",
                "splitTitle": "Diviser les cartes de crédit",
                "splitDesc": "Choisissez le nombre de cartes de crédit à diviser et cliquez sur confirmer",
                "noSplit": "Pas de division",
                "noPayments": "Pas de paiements",
                "payments": "Paiements",
                "paymentsNum": "Nombre de paiements",
                "withDebit": "Avec crédit",
                "splitBy": "Cartes de crédit",
                "toPay": "À payer",
                "splitApprove": "Confirmer",
                "currencyRate": "La facturation est effectuée en shekels - selon le taux du tourisme",
                "splitCurrentCardNum": "Division de crédit",
                "splitCurrentCardNumFrom": "Sur",
                "errorMessageToRetry": "Tentative n° {retryNum} - Échec du paiement par la société émettrice de la carte de crédit. Veuillez entrer à nouveau les détails de la carte ou une autre carte. Le site n'accepte que les cartes VISA et MASTERCARD."
            },
            "hotelTypes": {
                "Hotel": "Hôtel"
            },
            "hotelDetails": {
                "updateDates": "Mettre à jour les dates",
                "roomTypesToChooseFrom": "Types de chambres à choisir",
                "metapolicy": "Politique de l'hôtel",
                "metaExtraInfo": "Informations supplémentaires",
                "not_included": "Non inclus",
                "included": "Inclus",
                "price": "Au coût de",
                "address": "Adresse",
                "other": "Adresse",
                "Location": "Emplacement de l'hôtel",
                "At the boutique hotel": "À propos de l'hôtel",
                "Room amenities": "Équipements de la chambre",
                "parking": "Parking",
                "pets": "Animaux domestiques",
                "shuttle": "Navette vers l'aéroport (aller simple)",
                "internet": "Services Internet",
                "extra_bed": "Lit supplémentaire",
                "add_fee": "Frais supplémentaires",
                "children_meal": "Repas pour enfants",
                "check_in_check_out": "Arrivée et départ"
            },
            "sideFilters": {
                "sortBy": "Trier par",
                "recommended": "Recommandé",
                "price": "Prix",
                "clearSideFilters": "Effacer les filtres",
                "ts_type": "Types de vol",
                "auto_approve": "Approbation instantanée",
                "auto_approve_checked": "Affichage des résultats d'approbation instantanée",
                "from_date_hours": "Heure de départ du vol aller",
                "return_date_hours": "Heure de retour du vol",
                "airline": "Compagnie aérienne",
                "2": "Vols charter",
                "3": "Vols réguliers",
                "nonStop": "Vol direct",
                "stop": "Une escale",
                "stops_plural": "Escales",
                "hotel_type": "Types d'hébergement",
                "stops": "Nombre d'escales",
                "freeCancellation": "Annulation gratuite ?",
                "freeCancellationLabels": {
                    "1": "Uniquement annulation gratuite",
                    "0": "Sans annulation gratuite"
                }
            },
            "hotelAmenities": {
            },
            "general": {
                "usd": "USD",
                "euro": "EURO",
                "address": "Adresse",
                "type": "Type",
                "noResults": "Aucun résultat",
                "boardBasis": "Base de pension",
                "nights": "Nuits",
                "supplier": "Fournisseur",
                "airLine": "Compagnie aérienne",
                "price": "Prix",
                "totalRoomPrice": "Prix total",
                "priceForXRooms": "Prix pour X chambres",
                "hotel": "Hôtel",
                "hotel_stars": "Étoiles de l'hôtel",
                "minPrice": "Prix minimum",
                "BB": "BB",
                "HB": "HB",
                "cancel": "Annuler",
                "replaceShoppingCart": "Remplacer le panier",
                "replace": "Remplacer",
                "searchForUser": "Rechercher un utilisateur",
                "acceptCookiesTitle": "Ce site utilise des cookies pour vous garantir la meilleure expérience sur notre site.",
                "acceptCookiesBtn": "Accepter",
                "includesActivities": "{NUMBER} attractions offertes !",
                "includesActivity": "Inclut une attraction offerte !",
                "adults": "Adultes",
                "all_include": "Tout inclus",
                "breakfast": "Petit-déjeuner",
                "half_board": "Demi-pension",
                "half_board_kosher": "Demi-pension casher",
                "full_board": "Pension complète",
                "sleep_only": "Hébergement uniquement",
                "breakfast_kidush": "Petit-déjeuner et dîner du vendredi",
                "addToCard": "Ajouter à la commande",
                "chooseRoom": "Choisissez la composition et la chambre",
                "boardType": "Base d'hébergement",
                "filters": "Filtres",
                "rating": "Évaluation de l'hôtel",
                "children": "Enfants",
                "toDate": "Jusqu'à",
                "transfers": "Transferts",
                "from": "À partir de",
                "perPerson": "Par personne",
                "include": "Inclut",
                "noIncludeText": "Forfaits vacances",
                "flights": "Vols",
                "vacationAt": "Vacances à {DEST}",
                "fieldRequired": "Champ obligatoire",
                "showAll": "Afficher tout",
                "showLess": "Afficher moins",
                "flight": "vol",
                "h": "h",
                "min": "min",
                "availableSeats": "Places disponibles",
                "showGrossPrice": "Afficher le prix brut",
                "showNetPrice": "Afficher le prix net",
                "noRating": "Aucune évaluation",
                "bundleFlight": "Vol aller-retour",
                "trollyIsIncluded": "Chariot inclus",
                "trollyIsNotIncluded": "Pas de chariot",
                "suitcaseIsIncluded": "Valise incluse",
                "suitcaseIsNotIncluded": "Valise non incluse",
                "additionalSuitcases": "Inclut X valises",
                "additionalTrolly": "Inclut X chariots",
                "returnFlight": "Vol retour",
                "outboundFlight": "Vol aller",
                "agentMode": "Mode agent",
                "userMode": "Mode client",
                "suitcase": "Valise",
                "trolly": "Chariot",
                "packageSearchResult": "Les forfaits que nous avons trouvés pour vous",
                "day": "Jour",
                "selectDate": "Sélectionner les dates",
                "search": "Rechercher",
                "adult": "Adulte",
                "child": "Enfant",
                "infant": "Bébé",
                "infants": "Bébés"
            },
            "subsidizedForm": {
                "title": "Ajouter une subvention",
                "employee_id": "Numéro d'employé",
                "person_id": "Numéro d'identité (9 chiffres)",
                "validate": "Vérifier le code employé",
                "alreadyApplied": "Subvention déjà appliquée",
                "addOneMore": "Ajouter une autre subvention",
                "addOneMoreId": "Ajouter un autre employé",
                "fieldRequired": "Champ obligatoire",
                "priceReducedBy": "Prix réduit de",
                "forEmployee": "Pour l'employé",
                "employeeIs": "Identifié avec succès",
                "error4401": "Échec de l'identification, réessayez",
                "error4402": "Code identifié, mais déjà utilisé",
                "guestIdentifiedId": "Identifié par ID",
                "guestNotIdentified": "Vous devez vous identifier avant de vous inscrire",
                "clickToIdentify": "Cliquez pour identifier"
            },
            "uploadImage": {
                "uploadPassportImage": "Télécharger la photo du passeport",
                "somethingWentWrong": "Quelque chose s'est mal passé"
            },
            "flightButton": {
                "cardConditions": "Conditions de la carte",
                "flightTotalPrice": "Prix du vol aller simple",
                "total": "Total",
                "continueToOrder": "Passer à la commande",
                "addToCart": "Ajouter au panier",
                "lastPlace": "Dernières places disponibles",
                "pricePerPassenger": "Prix par passager"
            },
            "agentCommission": {
                "supplier": "Fournisseur",
                "commission": "Commission",
                "recommendedFee": "Frais recommandés",
                "transactionDetailsForAgent": "Détails de l'agent",
                "supplierNotes": "Notes du fournisseur",
                "cancellationConditions": "Conditions d'annulation",
                "addToCartToChangeFree": "Ajouter au panier pour changer la commission",
                "pricesShownAreNetPrices": "Les prix affichés sont nets",
                "pricesShownAreGrossPrices": "Les prix affichés sont bruts",
                "packageRemarks": "Remarques sur le forfait"
            }
        }
    }
};

export default translationsEn;