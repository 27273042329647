import React from 'react';
import { Modal } from 'antd';
import { PlanIdType } from '@skygroup/shared/constants/agency';
import UpgradePlanTemplate from './UpgradePlanTemplate';
import ModelApiMessage, { ApiStatusMessage } from '../crud-helpers/ModelApiMessage';

interface Props {
  plan_id: PlanIdType;
  upgradePlan_t: any;
  crud_t: any;
  handleUpgradeOnClick: (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<{
    success: boolean;
  }>;
  onCancel: () => void;
  open?: boolean;
  apiStatusMessage: ApiStatusMessage;
}

const UpgradePlanTemplateModal = ({
  plan_id,
  upgradePlan_t,
  crud_t,
  handleUpgradeOnClick,
  onCancel,
  open = true,
  apiStatusMessage,
}: Props) => {
  return (
    <Modal
      className={`upgrade-plan-template-modal`}
      open={open}
      footer={false}
      onCancel={onCancel}
      style={{ background: '#F5F5F5' }}
    >
      <UpgradePlanTemplate
        renderProPlan={plan_id === PlanIdType.REGULAR}
        upgradePlan_t={upgradePlan_t}
        handleUpgradeOnClick={handleUpgradeOnClick}
      />
      <ModelApiMessage apiStatusMessage={apiStatusMessage} t={crud_t} />
    </Modal>
  );
};

export default UpgradePlanTemplateModal;
