import { createSlice } from '@reduxjs/toolkit';
import { configInterface } from '../interfaces';
import {PriceView} from "../hooks/useGetGrossPrice";
// import moment from 'moment';

const params = new URL(window.location.href).searchParams;

const initialState: stateInterface = {
  config: {} as configInterface,
  translations: {},
  agentInfo: {},
  currentLang: "he_IL",
  isUpgradePlanModalOpen: false,
};

interface stateInterface {
  config: configInterface;
  translations: any;
  agentInfo: any;
  currentLang: any;
  isUpgradePlanModalOpen: boolean;
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setIsUpgradePlanModalOpen: (state, action) => {
      state.isUpgradePlanModalOpen = action.payload;
    },
    updateConfig: (state, action) => {
      state.config = action.payload;
    },
    updateLang: (state, action) => {
      state.currentLang = action.payload;
    },
    updateTranslations: (state, action) => {
      state.translations = action.payload;
    },
    updateAgentInfo: (state, action) => {
      state.agentInfo = action.payload;
    },
    updateAgentPriceView: (state, action) => {
      const priceView = action.payload.isGrossPrice
        ? PriceView.Gross
        : PriceView.Net;
      localStorage.setItem('price_view', JSON.stringify(priceView));

      state.agentInfo.agent.price_view = priceView;
    },
    updateModuleTheme: (state: any, action) => {
      const { moduleName, theme } = action.payload;
      state.config.modulesConfigs[moduleName].theme = theme;
    },
    updateSiteTheme: (state, action) => {
      const { theme } = action.payload;
      state.config.theme = theme;
    },
    updateLayout: (state, action) => {
      const { layouts } = action.payload;
      state.config.layouts = layouts;
    },
  },
});

export const {
  setIsUpgradePlanModalOpen,
  updateConfig,
  updateTranslations,
  updateAgentInfo,
  updateAgentPriceView,
  updateModuleTheme,
  updateSiteTheme,
  updateLayout,
  updateLang,
} = configSlice.actions;

export default configSlice.reducer;
