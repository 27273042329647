import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const TEMPORARY_DEFAULT_COMMISSION = 5;

const getAttractionCommission = () => {
  return TEMPORARY_DEFAULT_COMMISSION;
};

export default getAttractionCommission;
