import apiClient from '@skygroup/shared/API/apiClient';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import endpoints from '../../utils/endpoints';
interface customer {
  id: number;
  first_name: string;
  last_name: string;
  date_created: number;
  order_secret: string;
  customer_secret: string;
}
export const useGetCustomers = (
  name: string
): UseQueryResult<customer[], Error> => {
  const query = useQuery<any, Error>(
    ['get-customer-autocomplete', name],
    () =>
      apiClient
        .get(`${endpoints.AGENT.CUSTOMER_SEARCH}?name=${name}`)
        .then((response) => response.data.data.customers),
    {
      keepPreviousData: true,
      enabled: false,
      retry: false,
      staleTime: Infinity,
    }
  );

  return query;
};

export const upgradeRequestApi = async ({
  plan_id,
  setIsLoading,
}: {
  plan_id: string;
  setIsLoading: (loading: boolean) => void;
}) => {
  setIsLoading(true);
  return apiClient
    .post(`${endpoints.AGENT.GET_UPGRADE_REQUEST}?plan_id=${plan_id}`)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    })
    .finally(() => setIsLoading(false));
};
